import { getWithPrefix, postWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/downloadrecord/list', queryParam);
}

export async function downfile(params = {}) {
  const { id } = params;
  return getWithPrefix(`/privateFileTempUrl?fileId=${id}`);
}

export async function cancelfile(params = {}) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/downloadrecord/cancel', formData);
}

export async function delfile(params = {}) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/downloadrecord/del', formData);
}

import { get, post, getWithPrefix } from '@/utils/request';

export async function brandlist(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/goodsbrand/list', queryParam);
}

export async function brandview(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/goodsbrand/find', queryParam);
}

export async function composition(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/composition/list', queryParam);
}

export async function compositionview(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/composition/find', queryParam);
}

export async function list(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('queryParam', updateParam);
  return post('/boss/goodsbrand/list', formData);
}

export async function add(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/boss/goodsbrand/submit', formData);
}

export async function del(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/goodsbrand/del', updateParam);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _redux = require("redux");

var _flatten = _interopRequireDefault(require("flatten"));

var _invariant = _interopRequireDefault(require("invariant"));

var _window = _interopRequireDefault(require("global/window"));

var _utils = require("./utils");

function _default(_ref) {
  var reducers = _ref.reducers,
      initialState = _ref.initialState,
      plugin = _ref.plugin,
      sagaMiddleware = _ref.sagaMiddleware,
      promiseMiddleware = _ref.promiseMiddleware,
      _ref$createOpts$setup = _ref.createOpts.setupMiddlewares,
      setupMiddlewares = _ref$createOpts$setup === void 0 ? _utils.returnSelf : _ref$createOpts$setup;
  // extra enhancers
  var extraEnhancers = plugin.get('extraEnhancers');
  (0, _invariant.default)((0, _utils.isArray)(extraEnhancers), "[app.start] extraEnhancers should be array, but got ".concat((0, _typeof2.default)(extraEnhancers)));
  var extraMiddlewares = plugin.get('onAction');
  var middlewares = setupMiddlewares([promiseMiddleware, sagaMiddleware].concat((0, _toConsumableArray2.default)((0, _flatten.default)(extraMiddlewares))));

  var devtools = function devtools() {
    return function (noop) {
      return noop;
    };
  };

  if (process.env.NODE_ENV !== 'production' && _window.default.__REDUX_DEVTOOLS_EXTENSION__) {
    devtools = _window.default.__REDUX_DEVTOOLS_EXTENSION__;
  }

  var enhancers = [_redux.applyMiddleware.apply(void 0, (0, _toConsumableArray2.default)(middlewares))].concat((0, _toConsumableArray2.default)(extraEnhancers), [devtools(_window.default.__REDUX_DEVTOOLS_EXTENSION__OPTIONS)]);
  return (0, _redux.createStore)(reducers, initialState, _redux.compose.apply(void 0, (0, _toConsumableArray2.default)(enhancers)));
}
import {
  list,
  fetchParams,
  fetchBrands,
  exportData,
  goodslist,
  adddownload,
  quickAddGood,
} from '@/services/goodsAPI';
import { findProperty } from '@/services/propertyBagAPI';
import * as classificationAPI from '@/services/classificationAPI';

function convertChidren(data) {
  const newData = data;
  for (let i = 0; i < newData.length; i++) {
    const item = newData[i];
    const { name = '', id = '', children = [] } = item;
    newData[i].title = name;
    newData[i].value = id;
    newData[i].key = id;
    if (children.length) {
      convertChidren(children);
    }
  }
  return newData;
}

export default {
  namespace: 'goods',

  state: {
    list: [],
    params: [],
    categorys: [],
    brands: [],
    goodsList: [],
  },

  effects: {
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    *exportData({ payload, callback }, { call }) {
      const response = yield call(exportData, payload);
      if (callback) callback(response);
    },
    *fetchParams({ payload }, { call, put }) {
      const response = yield call(fetchParams, payload);
      if (!response) return;
      yield put({
        type: 'saveParams',
        payload: response,
      });
    },
    *fetchBrands({ payload }, { call, put }) {
      const response = yield call(fetchBrands, payload);
      if (!response) return;
      yield put({
        type: 'saveBrands',
        payload: response,
      });
    },
    *fetchCategory({ payload }, { call, put }) {
      const response = yield call(classificationAPI.list, payload);
      if (!response) return;
      yield put({
        type: 'saveCategory',
        payload: response,
      });
    },
    *findPriceType({ payload }, { call, put }) {
      const response = yield call(findProperty, payload);
      if (!response) return;
      yield put({
        type: 'savePriceType',
        payload: response,
      });
    },
    *fetchGoodsList({ payload, callback }, { call, put }) {
      const response = yield call(goodslist, payload);
      if (!response) return;
      if (callback) {
        callback(response);
        return;
      }
      yield put({
        type: 'saveGoodsList',
        payload: response,
      });
    },
    *createDownLoad({ payload, callback }, { call }) {
      const response = yield call(adddownload, payload);
      if (callback) callback(response);
    },
    *quickAddList({ payload, callback }, { call }) {
      const response = yield call(quickAddGood, payload);
      if (!response) return;
      if (callback) callback(response);
    },
  },

  reducers: {
    saveList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        list: data,
      };
    },
    saveParams(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        params: data,
      };
    },
    saveBrands(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        brands: data,
      };
    },
    saveCategory(state, { payload }) {
      const { data = [{ children: [] }] } = payload;
      const aData = convertChidren(data[0].children);
      return {
        ...state,
        categorys: aData,
      };
    },
    savePriceType(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        priceTypes: data,
      };
    },
    saveGoodsList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        goodsList: data,
      };
    },
    saveExportList(state, { payload }) {
      const { exportList } = state;
      return {
        ...state,
        exportList: { ...exportList, ...payload },
      };
    },
  },
};

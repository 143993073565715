import { get, post, getWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/categorynavigator/allgrade', queryParam);
}

export async function add(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/boss/categorynavigator/add', formData);
}

export async function edit(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/boss/categorynavigator/update', formData);
}

export async function allgrageList(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/boss/goodscategory/allgrageselectlist', queryParam);
}

export async function del(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/categorynavigator/del', updateParam);
}

export async function secondlist(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/categorynavigator/secondcatebybrand', queryParam);
}

import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'announcementModel', ...(require('D:/deergod/fDeerGod/src/models/announcementModel.js').default) });
app.model({ namespace: 'customer', ...(require('D:/deergod/fDeerGod/src/models/customer.js').default) });
app.model({ namespace: 'customerModal', ...(require('D:/deergod/fDeerGod/src/models/customerModal.js').default) });
app.model({ namespace: 'designerComPage', ...(require('D:/deergod/fDeerGod/src/models/designerComPage.js').default) });
app.model({ namespace: 'DesignerDesignModel', ...(require('D:/deergod/fDeerGod/src/models/DesignerDesignModel.js').default) });
app.model({ namespace: 'designmodels', ...(require('D:/deergod/fDeerGod/src/models/designmodels.js').default) });
app.model({ namespace: 'designshopcart', ...(require('D:/deergod/fDeerGod/src/models/designshopcart.js').default) });
app.model({ namespace: 'downloadModels', ...(require('D:/deergod/fDeerGod/src/models/downloadModels.js').default) });
app.model({ namespace: 'function', ...(require('D:/deergod/fDeerGod/src/models/function.js').default) });
app.model({ namespace: 'global', ...(require('D:/deergod/fDeerGod/src/models/global.js').default) });
app.model({ namespace: 'goodsModel', ...(require('D:/deergod/fDeerGod/src/models/goodsModel.js').default) });
app.model({ namespace: 'homepageModel', ...(require('D:/deergod/fDeerGod/src/models/homepageModel.js').default) });
app.model({ namespace: 'InformationModels', ...(require('D:/deergod/fDeerGod/src/models/InformationModels.js').default) });
app.model({ namespace: 'list', ...(require('D:/deergod/fDeerGod/src/models/list.js').default) });
app.model({ namespace: 'login', ...(require('D:/deergod/fDeerGod/src/models/login.js').default) });
app.model({ namespace: 'orderModel', ...(require('D:/deergod/fDeerGod/src/models/orderModel.js').default) });
app.model({ namespace: 'PostSaleModel', ...(require('D:/deergod/fDeerGod/src/models/PostSaleModel.js').default) });
app.model({ namespace: 'productclassify', ...(require('D:/deergod/fDeerGod/src/models/productclassify.js').default) });
app.model({ namespace: 'productmodels', ...(require('D:/deergod/fDeerGod/src/models/productmodels.js').default) });
app.model({ namespace: 'project', ...(require('D:/deergod/fDeerGod/src/models/project.js').default) });
app.model({ namespace: 'propertybag', ...(require('D:/deergod/fDeerGod/src/models/propertybag.js').default) });
app.model({ namespace: 'register', ...(require('D:/deergod/fDeerGod/src/models/register.js').default) });
app.model({ namespace: 'setting', ...(require('D:/deergod/fDeerGod/src/models/setting.js').default) });
app.model({ namespace: 'settlementmodels', ...(require('D:/deergod/fDeerGod/src/models/settlementmodels.js').default) });
app.model({ namespace: 'user', ...(require('D:/deergod/fDeerGod/src/models/user.js').default) });
app.model({ namespace: 'WholeHouseModel', ...(require('D:/deergod/fDeerGod/src/models/WholeHouseModel.js').default) });
app.model({ namespace: 'wxHomepageModel', ...(require('D:/deergod/fDeerGod/src/models/wxHomepageModel.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}

import {
  shopcartlist,
  updateshopcart,
  delshopcart,
  // cleanshopcart,
  exportxshshopcart,
  shopcartcount,
  cleaninval,
} from '@/services/shopTrolleyAPI';
import ProductSelectSpec from '@/components/ProductSelectSpec';

const convertGoods = ProductSelectSpec.convertGoods;

export default {
  namespace: 'designshopcart',
  state: {
    list: [],
    settlementlist: [],
    shopCartCount: 0,
  },

  effects: {
    *fetchList(_, { call, put }) {
      const response = yield call(shopcartlist, { pageNo: 1, pageSize: 100 }) || {};
      if (!response) return;

      const { data: { modelList = [] } = {} } = response;
      const list = modelList.map(item => {
        const { goodsInfo = {}, ...others } = item;
        const { goodsInfoAdded } = goodsInfo;
        const { goods = {}, ...otherGoodsInfo } = goodsInfo;
        const newGoodsInfo = { ...otherGoodsInfo }; // 没有商品
        const { goodsOpenSpecValues: productSpecs = [] } = newGoodsInfo;
        const { goodsOpenSpecValues: goodsSpecs = [], goodsInfos: products } = goods;

        const { selectedSpecValues = [], allSpecNameList = [] } = convertGoods({
          productSpecs,
          goodsSpecs,
          products,
        });
        const speced = { selectedSpecValues, allSpecNameList, selectedProduct: goodsInfo };
        let checked = true;
        if (goodsInfoAdded !== '1') {
          checked = false;
        }
        return {
          ...others,
          goodsInfo: newGoodsInfo,
          goods,
          checked,
          speced,
        };
      });

      yield put({
        type: 'saveList',
        payload: { list },
      });
    },
    *changeProduct({ payload }, { call, put, select }) {
      const { shopCartItemId } = payload;
      const { list } = yield select(state => state.designshopcart);
      const selectItem = list.find(v => shopCartItemId === v.id) || {};
      const newGoodsInfo = selectItem.speced.selectedProduct;
      if (!newGoodsInfo) {
        return;
      }
      selectItem.goodsInfo = newGoodsInfo;
      selectItem.showSpec = false;
      const newList = list.map(i => i);

      yield put({
        type: 'saveList',
        payload: { list: newList },
      });

      const { id, qty } = selectItem;
      const { id: goodsInfoId } = newGoodsInfo;
      yield call(updateshopcart, { id, qty, goodsInfoId }) || {};
    },
    *changeProductNum({ payload, callback }, { call, put, select }) {
      const { list } = yield select(state => state.designshopcart);
      const { id, num } = payload;
      const selectItem = list.find(v => id === v.id) || {};
      const {
        goodsInfo: { id: goodsInfoId },
      } = selectItem;
      const response = yield call(updateshopcart, { id, qty: num, goodsInfoId }) || {};
      if (callback) callback(response);
      yield put({
        type: 'updateList',
        payload: { id, num },
      });
    },
    *delShopCartList({ payload, callback }, { call, put }) {
      const response = yield call(delshopcart, { ...payload }) || {};
      const {
        data: { code },
      } = response;
      if (code === '203') {
        yield put({
          type: 'updateDelList',
          payload,
        });
      }
      if (callback) callback(response);
    },

    *exportProduct(_, { call, select }) {
      const { list } = yield select(state => state.designshopcart);
      const ids = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const { checked, id } = item;
        if (checked) {
          ids.push(id);
        }
      }
      yield call(exportxshshopcart, { ids });
    },
    *fetchShopCount({ payload, callback }, { call, put }) {
      const response = yield call(shopcartcount, payload) || {};
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveShopCartCount',
        payload: response.data || 0,
      });
    },
    *clearInval({ callback }, { call }) {
      const response = yield call(cleaninval) || {};
      if (callback) callback(response);
    },
  },

  reducers: {
    clickSpec(state, action) {
      const { payload: { shopCartItemId } = {} } = action;
      const { list, ...others } = state;
      const newList = list.map(item => {
        const newItem = { ...item };
        if (item.id === shopCartItemId) {
          newItem.showSpec = !newItem.showSpec;
          if (newItem.showSpec) {
            const { goods, goodsInfo = {} } = newItem;
            const { goodsOpenSpecValues: productSpecs = [] } = goodsInfo;
            const { goodsOpenSpecValues: goodsSpecs = [], goodsInfos: products } = goods;

            const { selectedSpecValues = [], allSpecNameList = [] } = convertGoods({
              productSpecs,
              goodsSpecs,
              products,
            });
            const speced = { selectedSpecValues, allSpecNameList, selectedProduct: goodsInfo };
            newItem.speced = speced;
          }
        }
        return newItem;
      });

      return {
        ...others,
        list: newList,
      };
    },
    changeSpecValue(state, action) {
      const {
        payload: { shopCartItemId, product, selectedSpecValues, allSpecNameList } = {},
      } = action;
      const { list, ...others } = state;
      const newList = list.map(item => {
        const newItem = { ...item };
        let {
          speced: { selectedProduct },
        } = newItem;
        if (product) {
          selectedProduct = product;
        }
        if (item.id === shopCartItemId) {
          newItem.speced = { selectedSpecValues, allSpecNameList, selectedProduct };
        }
        return newItem;
      });

      return {
        ...others,
        list: newList,
      };
    },

    saveList(state, action) {
      const { payload: { list } = {} } = action;
      return {
        ...state,
        list,
      };
    },
    updateList(state, action) {
      const {
        payload: { id, num },
      } = action;
      const { list } = state;
      const selectItem = list.find(v => id === v.id);
      selectItem.qty = num;
      const newList = list.map(i => i);
      return {
        ...state,
        list: newList,
      };
    },
    updateDelList(state, action) {
      const { payload } = action;
      const { list } = state;
      const newList = [];
      const { id, ids } = payload;
      let newIdArr = [];
      if (id) {
        newIdArr.push(id);
      } else {
        newIdArr = ids;
      }
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (newIdArr.indexOf(item.id) === -1) {
          newList.push(item);
        }
      }
      return {
        ...state,
        list: newList,
      };
    },
    checkproduct(state, action) {
      const {
        payload: { id },
      } = action;
      const { list } = state;
      const selectItem = list.find(v => id === v.id);
      selectItem.checked = !selectItem.checked;
      const newList = list.map(i => i);
      return {
        ...state,
        list: newList,
      };
    },
    checkAllproduct(state, action) {
      const {
        payload: { checked },
      } = action;
      const { list } = state;
      for (let i = 0; i < list.length; i++) {
        const { goodsInfo: { goodsInfoAdded } = {} } = list[i];
        if (goodsInfoAdded === '1') {
          list[i].checked = checked;
        }
      }
      const newList = list.map(i => i);
      return {
        ...state,
        list: newList,
      };
    },
    saveShopCartCount(state, { payload }) {
      return {
        ...state,
        shopCartCount: payload,
      };
    },
  },
};

import { list, downfile, cancelfile, delfile } from '@/services/downLoadAPI';
import { response2PageList } from '@/utils/requestUtils';

export default {
  namespace: 'download',
  state: {
    exportList: {
      list: [],
      pagination: {
        page: 1,
        pageSize: 10,
        totalCount: 0,
      },
    },
    exportModalVisible: false,
  },
  effects: {
    *fetchList({ payload, callback }, { call, put, select }) {
      const {
        exportList: {
          pagination: { pageSize },
        },
      } = yield select(state => state.download);
      const response = yield call(list, {
        ...payload,
        pageSize,
      }) || {};
      const { list: excelList, pagination } = response2PageList(payload, response);
      if (callback) {
        callback(excelList);
      }
      yield put({
        type: 'saveList',
        payload: {
          list: excelList,
          pagination: { ...pagination, page: payload.page, pageSize },
        },
      });
    },
    *downLoadFile({ payload, callback }, { call }) {
      const response = yield call(downfile, {
        ...payload,
      }) || {};
      if (callback) callback(response);
    },
    *cancelFile({ payload, callback }, { call }) {
      const response = yield call(cancelfile, {
        ...payload,
      }) || {};
      if (callback) callback(response);
    },
    *delFile({ payload, callback }, { call }) {
      const response = yield call(delfile, {
        ...payload,
      }) || {};
      if (callback) callback(response);
    },
  },
  reducers: {
    saveList(state, { payload }) {
      const { exportList } = state;
      return {
        ...state,
        exportList: { ...exportList, ...payload },
      };
    },
    toggleExportModal(state, { payload }) {
      const { exportModalVisible } = payload;
      return {
        ...state,
        exportModalVisible,
      };
    },
  },
};

import { list, add, findInfo, update, publish, unPublish, del } from '@/services/announcementAPI';
import * as NoticeManageAPI from '@/services/NoticeManageAPI';
import { fetchDesign } from '@/services/worksDisplayAPI';
import { findProperty } from '@/services/propertyBagAPI';
import { findStaffList } from '@/services/org';

export default {
  namespace: 'announcement',

  state: {
    view: {},
    list: [],
    typeData: {},
    editData: {},
    designerList: [],
    roles: [],
    publisherList: [],
    sendCount: 0,
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (!response) return;
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    *fetchType({ payload, callback }, { call, put }) {
      const response = yield call(findProperty, { ...payload });
      if (!response) return;
      yield put({
        type: 'saveType',
        payload: response,
      });
      if (callback) callback(response);
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(add, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *fetchEditData({ payload, callback }, { call, put }) {
      const { id } = payload;
      const response = yield call(findInfo, { id });
      if (!response) return;
      yield put({
        type: 'setEditData',
        payload: response,
      });
      if (callback) callback(response);
    },
    *fetchDesign({ payload }, { call, put }) {
      const response = yield call(fetchDesign, payload);
      if (!response) return;
      yield put({
        type: 'saveDesign',
        payload: response,
      });
    },
    *fetchPublisher({ payload }, { call, put }) {
      const response = yield call(findStaffList, payload);
      if (!response) return;
      yield put({
        type: 'savePublisher',
        payload: response,
      });
    },
    *calcSendCount({ payload, callback }, { call, put }) {
      const response = yield call(NoticeManageAPI.calcSendCount, payload) || {};
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveSendCount',
        payload: response.data || 0,
      });
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *publish({ payload, callback }, { call }) {
      const { id } = payload;
      const response = yield call(publish, { id });
      if (!response) return;
      if (callback) callback(response);
    },
    *upPublish({ payload, callback }, { call }) {
      const { id } = payload;
      const response = yield call(unPublish, { id });
      if (!response) return;
      if (callback) callback(response);
    },
    *del({ payload, callback }, { call }) {
      const { id } = payload;
      const response = yield call(del, { id });
      if (!response) return;
      if (callback) callback(response);
    },
    *fetchRoles({ payload, callback }, { call, put }) {
      const response = yield call(NoticeManageAPI.roleNames, payload) || {};
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveRoles',
        payload: response.data || [],
      });
    },
  },

  reducers: {
    saveList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        list: data,
      };
    },
    setEditData(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        editData: data,
      };
    },
    saveType(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        typeData: data,
      };
    },
    saveDesign(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        designerList: data,
      };
    },
    savePublisher(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        publisherList: data,
      };
    },
    saveRoles(state, { payload }) {
      return {
        ...state,
        roles: payload,
      };
    },
    saveSendCount(state, { payload }) {
      return {
        ...state,
        sendCount: payload,
      };
    },
  },
};

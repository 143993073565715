import { getWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/announcement/readlist', queryParam);
}

export async function find(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/announcement/find', queryParam);
}

export async function findproperty(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/propertybag/findproperty', queryParam);
}

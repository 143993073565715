import { wxlist, content, update, del } from '@/services/HomepageAPI';
import * as goodsAPI from '@/services/goodsAPI';
import * as designerDesignAPI from '@/services/Designer/DesignerDesignAPI';

import uuidv1 from 'uuid/v1';
import { message } from 'antd';

const defaultTabExtInfo = () => [
  { title: '默认标签1', key: '1', isShow: true },
  { title: '默认标签2', key: '2', isShow: true },
  { title: '默认标签3', key: '3', isShow: true },
];

const getDefaultView = () => ({
  tabExtInfo: defaultTabExtInfo(),
});

const checkHasEditingFloor = ({ homePageFloors, key }) => {
  const editingFloor = homePageFloors.find(item => item.showEdit && item.key !== key);
  if (editingFloor) {
    // 有其他楼层正在编辑，不能编辑当前楼层
    message.error(`楼层${editingFloor.floorNo}未确认，请先确认`);
    return true;
  }
  return false;
};

export default {
  namespace: 'wxhomepage',

  state: {
    list: {},
    view: getDefaultView(),
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(wxlist, payload);
      if (!response) return;
      yield put({
        type: 'saveList',
        payload: response,
      });
    },

    *fetchContent({ payload, callback }, { call, put }) {
      const response = yield call(content, payload);
      if (!response) return;
      if (callback) callback(response);
      const { data } = response;
      const { data: { tabExtInfo } = {} } = response;
      if (tabExtInfo) {
        data.tabExtInfo = JSON.parse(tabExtInfo);
      }
      yield put({
        type: 'saveView',
        payload: data,
      });
    },

    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },

    *fetchGoodsList({ payload, callback }, { call }) {
      const response = yield call(goodsAPI.list, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *fetchCompositionView({ payload, callback }, { call }) {
      const response = yield call(designerDesignAPI.find, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *delete(
      {
        payload: { id },
        callback,
      },
      { call }
    ) {
      const response = yield call(del, { id });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
  },

  reducers: {
    addFloor(state, action) {
      const {
        payload: { floorType, tabInfo, getFloorDataDefault = i => i },
      } = action;
      // TODO cal floorNo
      const {
        view: { homePageFloors = [], ...othersView },
        ...others
      } = state;
      if (checkHasEditingFloor({ homePageFloors, key: null })) {
        return state;
      }
      let floorNo = 1;
      const activeKeyFloor = homePageFloors.filter(f => f.tabInfo === tabInfo);
      if (activeKeyFloor.length > 0) {
        // 最后一个楼层加1
        floorNo = activeKeyFloor[activeKeyFloor.length - 1].floorNo + 1;
      }
      const floorData = getFloorDataDefault({
        showEdit: true,
        floorType,
        floorNo,
        key: uuidv1(),
        tabInfo,
      });
      homePageFloors.push(floorData);
      return {
        ...others,
        view: { ...othersView, homePageFloors: [...homePageFloors] },
      };
    },
    deleteFloor(state, action) {
      const {
        payload: { key },
      } = action;
      const {
        view: { homePageFloors = [], ...othersView },
        ...others
      } = state;

      return {
        ...others,
        view: { ...othersView, homePageFloors: homePageFloors.filter(item => item.key !== key) },
      };
    },
    editorFloor(state, action) {
      const {
        payload: { key },
      } = action;
      // TODO cal floorNo
      const {
        view: { homePageFloors = [], ...othersView },
        ...others
      } = state;
      if (checkHasEditingFloor({ homePageFloors, key })) {
        return state;
      }

      const index = homePageFloors.findIndex(item => item.key === key);
      homePageFloors[index] = { ...homePageFloors[index], showEdit: true };

      return {
        ...others,
        view: { ...othersView, homePageFloors: [...homePageFloors] },
      };
    },
    changeFloor(state, action) {
      const {
        payload: { key, ...newFloorData },
      } = action;
      const {
        view: { homePageFloors = [], ...othersView },
        ...others
      } = state;
      const neWhomePageFloors = [...homePageFloors];

      const index = neWhomePageFloors.findIndex(item => item.key === key);
      neWhomePageFloors[index] = { ...neWhomePageFloors[index], ...newFloorData };
      const { showEdit } = neWhomePageFloors[index];
      if (!showEdit) {
        // 关闭编辑的时候，对数据精选排序
        neWhomePageFloors.sort((f1, f2) => f1.floorNo - f2.floorNo);
        const tabKeys = new Set(neWhomePageFloors.map(f => f.tabInfo));
        tabKeys.forEach(tabKey => {
          const tabFloors = neWhomePageFloors.filter(f => f.tabInfo === tabKey);
          for (let i = 0; i < tabFloors.length; i++) {
            tabFloors[i].floorNo = i + 1;
          }
        });
      }

      return {
        ...others,
        view: { ...othersView, homePageFloors: [...neWhomePageFloors] },
      };
    },
    saveGoods(state, action) {
      const { payload: { data } = {} } = action;
      return {
        ...state,
        goods: data,
      };
    },
    saveList(state, action) {
      const { payload: { data } = {} } = action;
      return {
        ...state,
        list: data,
      };
    },
    changeView(state, { payload }) {
      return {
        ...state,
        view: { ...state.view, ...payload },
      };
    },
    newView(state) {
      return {
        ...state,
        view: getDefaultView(),
      };
    },
    saveView(state, { payload }) {
      let { tabExtInfo } = payload;
      const { homePageFloors: floors = [] } = payload;
      if (!Array.isArray(tabExtInfo)) {
        tabExtInfo = defaultTabExtInfo();
      }
      // 回填key
      const homePageFloors = floors.map(
        ({
          key: fKey = uuidv1(),
          goodsInfoIdsArray = [],
          compositionIdsArray = [],
          banners = [],
          ...fothers
        }) => ({
          ...fothers,
          compositionIdsArray: compositionIdsArray.map(({ key = uuidv1(), ...others }) => ({
            ...others,
            key,
          })),
          goodsInfoIdsArray: goodsInfoIdsArray.map(({ key = uuidv1(), ...others }) => ({
            ...others,
            key,
          })),
          banners: banners.map(({ key = uuidv1(), ...others }) => ({
            ...others,
            key,
          })),
          key: fKey,
        })
      );

      return {
        ...state,
        view: { ...payload, homePageFloors, tabExtInfo },
      };
    },
  },
};

import { classify } from '@/services/productAPI';
// import MtaH5 from 'mta-h5-analysis';

function getLeaf(data = [], leafArr) {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const { children } = item;
    const imgSrc = item.imgSrc;
    if (imgSrc) {
      leafArr.push(item);
    }
    if (children) {
      getLeaf(children, leafArr);
    }
  }
  leafArr.sort((a, b) => {
    const { golbalSortNo: aSortNo = 0 } = a;
    const { golbalSortNo: bSortNo = 0 } = b;
    return aSortNo - bSortNo;
  });
  return leafArr;
}

function getTreeNodeMap(data, treeMap, parent) {
  const treeMapObj = treeMap;
  for (let i = 0; i < data.length; i++) {
    const nodeItem = data[i];
    const { id: nodeItemId, children } = nodeItem;
    if (parent) {
      nodeItem.parentNode = parent;
    }
    treeMapObj[nodeItemId] = nodeItem;
    if (children && children.length > 0) {
      children.sort((a, b) => {
        const { sortNo: aSortNo = 0 } = a;
        const { sortNo: bSortNo = 0 } = b;
        return aSortNo - bSortNo;
      });
      getTreeNodeMap(children, treeMapObj, nodeItem);
    }
  }
  return treeMapObj;
}

function getTreePath(leaf, treeMap) {
  const path = [];
  let currentNode = leaf;
  for (; currentNode; ) {
    const { parentId } = currentNode;
    if (parentId) {
      // 剔除根节点
      path.push(currentNode);
    }
    currentNode = treeMap[parentId];
  }
  path.reverse();
  return path;
}

export default {
  namespace: 'productclassify',

  state: {
    productclassifyLevesMap: {}, // 所有导航分类叶子映射
    navBarAllTreeNodeMap: {}, // 所有导航分类每个节点的映射，包含当前节点的孩子与父亲
    currentClassify: {}, // 当前选中的分类
    currentClassifyPath: [],
    productSearchData: {},
    storageSearchData: {},
    pendantSearchData: {},
    activePath: '',
    currentViewType: '', // 当前选中的viewType
    // childrenClassify: [],
    pictureClassity: [],
  },

  effects: {
    *fetchProductClassify({ payload }, { put, call }) {
      const { targetType: viewType } = payload;
      const response = yield call(classify, { ...payload, targetType: 'furniture' });
      if (!response) {
        return;
      }
      const { data = [{ children: [] }] } = response;
      const { children: grades } = data[0];
      const leafArr = [];
      const treeMap = {};
      let rootId = 100;
      if (viewType === 'furniture') {
        rootId = 100;
      } else if (viewType === 'ornament') {
        rootId = 97;
      }
      // 寻找对应的一级类目
      const targetGrade = grades.find(item => item.id === rootId);
      const classifyLeaves = getLeaf(targetGrade.children, leafArr, rootId);
      const treeNodeMap = getTreeNodeMap(data, treeMap);
      yield put({
        type: 'saveclassify',
        payload: { classifyLeaves, treeNodeMap, viewType },
      });
    },

    *fetchPictureClassify({ payload }, { put, call }) {
      const { targetType: viewType } = payload;
      const response = yield call(classify, { ...payload, targetType: viewType });
      if (!response) return;
      const { data = [{ children: [] }] } = response;
      const { children: grades } = data[0];
      yield put({
        type: 'savePicClassify',
        payload: grades,
      });
      // const { data = [{ children: [] }] } = response;
      // const grades = response.data || [];
      // const leafArr = [];
      // const treeMap = {};
      // const rootId = 406;
      // // if (viewType === 'furniture') {
      // //   rootId = 100;
      // // } else if (viewType === 'ornament') {
      // //   rootId = 97;
      // // }
      // // 寻找对应的一级类目
      // const targetGrade = grades.find(item => item.id === rootId);
      // const classifyLeaves = getLeaf(targetGrade.children, leafArr, rootId);
      // const treeNodeMap = getTreeNodeMap(data, treeMap);
      // yield put({
      //   type: 'saveclassify',
      //   payload: { classifyLeaves, treeNodeMap, viewType },
      // });
    },

    *fetchDesignerProductClassify({ payload }, { put, call, select }) {
      const { navBarAllTreeNodeMap } = yield select(state => state.productclassify);
      const { targetType: viewType } = payload;
      if (navBarAllTreeNodeMap[viewType]) {
        // 如果已经存在，则不再加载
        return;
      }
      const response = yield call(classify, { ...payload, targetType: viewType }) || {};
      const { data = [{ children: [] }] } = response;
      const treeMap = {};
      const treeNodeMap = getTreeNodeMap(data, treeMap);
      yield put({
        type: 'saveclassify',
        payload: { rootNode: data[0], treeNodeMap, viewType },
      });
    },
  },

  reducers: {
    saveclassify(state, { payload }) {
      const { navBarAllTreeNodeMap, productclassifyLevesMap } = state;
      const { viewType, rootNode, classifyLeaves, treeNodeMap } = payload;
      // let { viewType } = payload;
      // if (viewType === 'pic') viewType = 'furniture';
      return {
        ...state,
        rootNode,
        currentViewType: viewType,
        productclassifyLevesMap: { ...productclassifyLevesMap, [viewType]: classifyLeaves },
        navBarAllTreeNodeMap: { ...navBarAllTreeNodeMap, [viewType]: treeNodeMap },
      };
    },

    savePicClassify(state, { payload }) {
      return {
        ...state,
        pictureClassity: payload,
      };
    },

    pitchClassify(state, { payload }) {
      const { id } = payload;
      const { navBarAllTreeNodeMap, currentViewType } = state;
      const treeMap = navBarAllTreeNodeMap[currentViewType];
      const currentClassify = treeMap[id];
      // const { id: cid, name: cname } = currentClassify;
      // MtaH5.clickStat('pl002', { cid, cname });
      const currentClassifyPath = getTreePath(currentClassify, treeMap);
      // 更新当前节点，并计算当前节点数
      return {
        ...state,
        currentClassify,
        currentClassifyPath,
      };
    },

    pitchClassifyParent(state, { payload }) {
      const { id } = payload;
      const { navBarAllTreeNodeMap, currentViewType } = state;
      const treeMap = navBarAllTreeNodeMap[currentViewType];
      const { parentId } = treeMap[id];
      const currentClassify = treeMap[parentId] || {};
      const currentClassifyPath = getTreePath(currentClassify, treeMap);
      // 更新当前节点，并计算当前节点数
      return {
        ...state,
        currentClassify,
        currentClassifyPath,
      };
    },

    // saveChildrenClassify(state, { payload }) {
    //   return {
    //     ...state,
    //     childrenClassify: payload,
    //   };
    // },

    saveProductSearch(state, { payload }) {
      return {
        ...state,
        productSearchData: { ...payload },
      };
    },
    saveStorageSearch(state, { payload }) {
      return {
        ...state,
        storageSearchData: { ...payload },
      };
    },
    savePendantSearch(state, { payload }) {
      return {
        ...state,
        pendantSearchData: { ...payload },
      };
    },

    setActivePath(state, { payload }) {
      return {
        ...state,
        activePath: payload,
      };
    },

    resetCurrentClassify(state) {
      return {
        ...state,
        currentClassifyPath: [],
        currentClassify: {},
      };
    },

    resetNavBarAllTreeNodeMap(state) {
      return {
        ...state,
        navBarAllTreeNodeMap: {},
      };
    },
  },
};

import { productlist, productview, relaGoods, baiduproductlist } from '@/services/productAPI';
import { response2PageList } from '@/utils/requestUtils';
import { addgoodsinfo } from '@/services/shopTrolleyAPI';
import * as PackagedMealAPI from '@/services/PackagedMealAPI';

function getNewSpecList(
  newSelectedItem,
  count,
  selectedSpecValues,
  currentGoodsInfo,
  allSpecNameListModel
) {
  let newSelectedSpecValues = selectedSpecValues;

  if (count === 1 || (count !== 0 && count <= selectedSpecValues.length)) {
    newSelectedSpecValues = [];
    // 新选择规格数组,先将新点击的规格加入
    newSelectedSpecValues.push(newSelectedItem);
    // 循环已选择的规格,将不同于新点击规格的已选规格加入
    selectedSpecValues.forEach(selectedSpecValue => {
      if (selectedSpecValue.spec.id !== newSelectedItem.spec.id) {
        newSelectedSpecValues.push(selectedSpecValue);
      }
    });

    const existNewSelectedSpecGoodsInfos = [];
    // 遍历所有兄弟货品下的所有规格,找到当前选中值,赋进匹配货品列表
    if (currentGoodsInfo.goods.goodsInfos) {
      currentGoodsInfo.goods.goodsInfos.forEach(brotherGoodsInfo => {
        if (brotherGoodsInfo.goodsOpenSpecValues != null) {
          brotherGoodsInfo.goodsOpenSpecValues.forEach(brotherInfoSpec => {
            if (brotherInfoSpec.id === newSelectedItem.id) {
              existNewSelectedSpecGoodsInfos.push(brotherGoodsInfo);
            }
          });
        }
      });
    }
    if (allSpecNameListModel.length > 1) {
      // 第一次时候清空规格下表下可选择
      if (count === 1) {
        allSpecNameListModel.forEach(specNameItem => {
          specNameItem.specValues.forEach(specValue => {
            const tempValue = specValue;
            tempValue.canBeSelect = false;
          });
        });
      }
      // 循环匹配列表,将存在的规格向全规格赋予可选
      existNewSelectedSpecGoodsInfos.forEach(existNewSelectedSpecGoodsInfo => {
        existNewSelectedSpecGoodsInfo.goodsOpenSpecValues.forEach(existSpec => {
          // 判断可选
          allSpecNameListModel.forEach(({ specValues }) => {
            for (let i = 0; i < specValues.length; i += 1) {
              const specValue = specValues[i];
              if (existSpec.id === specValue.id) {
                // 匹配到规格值id
                specValue.canBeSelect = true;
              }
            }
          });
        });
      });
      // 筛选已选列表中实际不能选的规格
      const selectedButCantExistSpecValues = [];
      newSelectedSpecValues.forEach(newSelectedSpecValue => {
        let existSpecValueCount = 0;
        existNewSelectedSpecGoodsInfos.forEach(existNewSelectedSpecGoodsInfo => {
          existNewSelectedSpecGoodsInfo.goodsOpenSpecValues.forEach(existSpec => {
            if (newSelectedSpecValue.id === existSpec.id) {
              // 已选规格不是最新点击规格,且不等于可存在规格,+1
              existSpecValueCount += 1;
            }
          });
        });
        // 已选规格满值,既不存在,去除已选
        if (existSpecValueCount === 0) {
          selectedButCantExistSpecValues.push(newSelectedSpecValue);
        }
      });
      if (selectedButCantExistSpecValues.length > 0) {
        selectedButCantExistSpecValues.forEach(item => {
          newSelectedSpecValues.splice(newSelectedSpecValues.findIndex(v => v.id === item.id), 1);
        });
      }
    }

    const tempSelectedValues = [...newSelectedSpecValues];
    if (tempSelectedValues.length > 1) {
      tempSelectedValues.splice(tempSelectedValues.findIndex(v => v.id === newSelectedItem.id), 1);
    }
    const newItem = tempSelectedValues[0];
    return getNewSpecList(
      newItem,
      count + 1,
      newSelectedSpecValues,
      currentGoodsInfo,
      allSpecNameListModel
    );
  }

  return { selectedSpecValues: newSelectedSpecValues, allSpecNameList: allSpecNameListModel };
}

function getNewGoods(data) {
  const {
    goodsOpenSpecValues: goodsInfoSpecs = [],
    goods: { goodsOpenSpecValues: objGoodsOpenSpecValues = [], goodsInfos: objGoodsInfos = [] },
  } = data;
  const selectedSpecValuesModel = [];
  goodsInfoSpecs.forEach(goodsInfoSpec => {
    selectedSpecValuesModel.push(goodsInfoSpec);
  });
  const allSpecNameListModel = [];
  // 先组装出所有的规格名称,规格值数组
  objGoodsOpenSpecValues.forEach(value => {
    if (allSpecNameListModel.length === 0) {
      allSpecNameListModel.push({
        specName: value.spec.specName,
        specId: value.spec.id,
        specValues: [value],
      });
    } else {
      let addFlag = false;
      allSpecNameListModel.forEach((specNameItem, index) => {
        if (specNameItem.specName === value.spec.specName) {
          if (!addFlag) {
            specNameItem.specValues.push(value);
            addFlag = true;
          }
        } else if (index + 1 === allSpecNameListModel.length && !addFlag) {
          allSpecNameListModel.push({
            specName: value.spec.specName,
            specId: value.spec.id,
            specValues: [value],
          });
          addFlag = true;
        }
      });
    }
  });
  // 遍历所有兄弟货品下的所有规格
  if (objGoodsInfos) {
    objGoodsInfos.forEach(brotherGoodsInfo => {
      if (brotherGoodsInfo.goodsOpenSpecValues != null) {
        brotherGoodsInfo.goodsOpenSpecValues.forEach(brotherInfoSpec => {
          // 找到列表中对应的specValueId,设置为可选
          allSpecNameListModel.forEach(specNameItem => {
            specNameItem.specValues.forEach(specValue => {
              const tempValue = specValue;
              if (!tempValue.canBeSelect) {
                if (specValue.id === brotherInfoSpec.id) {
                  tempValue.canBeSelect = true;
                }
              }
            });
          });
        });
      }
    });
  }
  return { selectedSpecValuesModel, allSpecNameListModel };
}

export default {
  namespace: 'productmodels',

  state: {
    productSearch: [], // 商品搜索条件
    brandsSearch: [], // 商品品牌搜索条件
    colorSearch: [], // 颜色搜索
    productlistData: {
      list: [], // 商品列表
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
    relaGoods: [], // 关联商品
    productdeatils: {},
    selectedSpecValues: [], // 货品详情，选中规格
    allSpecNameList: [], // 货品详情，商品下所有货品规格
    inputSearch: undefined,
    relevanceDesign: [],
  },

  effects: {
    *fetchProduct({ payload, callback }, { call, put }) {
      const { pageNo, ...others } = payload;
      const response = yield call(productlist, { pageNo, ...others });
      const { list: listData, pagination } = response2PageList(payload, response);
      pagination.current = pageNo;
      const { data: { params, brands = [], color = [] } = {} } = response;
      if (callback) callback(response);
      yield put({
        type: 'fetch',
        payload: { list: listData, pagination, productSearch: params || [], brands, color },
      });
    },

    *fetchBaiduImg({ payload, callback }, { call, put }) {
      const response = yield call(baiduproductlist, { ...payload });
      if (!response) return;
      if (callback) callback(response);
      const { data = [] } = response;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        item.goodsInfoId = item.id;
      }
      yield put({
        type: 'fetch',
        payload: { list: data },
      });
    },

    *findproduct({ payload, callback }, { call, put }) {
      const response = yield call(productview, payload);
      const { data = {} } = response;
      const { selectedSpecValuesModel = [], allSpecNameListModel = [] } = getNewGoods(data);
      if (callback) callback(data);
      yield put({
        type: 'finddetails',
        payload: {
          data,
          selectedSpecValues: selectedSpecValuesModel,
          allSpecNameList: allSpecNameListModel,
        },
      });
      const { goodsId, goods: { goodsInfos = [] } = {} } = data;
      if (goodsId) {
        const res = yield call(relaGoods, { goodsId });
        yield put({
          type: 'relaGoods',
          payload: res,
        });
      }
      const goodsInfoIds = [];
      for (let i = 0; i < goodsInfos.length; i++) {
        const item = goodsInfos[i] || {};
        const { id } = item;
        goodsInfoIds.push(id);
      }
      const res = yield call(PackagedMealAPI.list, {
        page: 1,
        pageSize: 999,
        goodsInfoIds,
      });
      if (!res) return;
      yield put({
        type: 'saveDesign',
        payload: res,
      });
    },

    *updateSelectedSpecValues({ payload }, { put, select }) {
      const { item: newSelectedItem, count } = payload;
      const goods = yield select(state => state.productmodels);
      const {
        selectedSpecValues: selectedSpecValuesModel,
        productdeatils: currentGoodsInfo,
        allSpecNameList: allSpecNameListModel,
      } = goods;
      const { selectedSpecValues, allSpecNameList } = getNewSpecList(
        newSelectedItem,
        count,
        selectedSpecValuesModel,
        currentGoodsInfo,
        allSpecNameListModel
      );
      yield put({
        type: 'updateDetails',
        payload: {
          selectedSpecValues,
          allSpecNameList,
        },
      });
    },

    *addgoodsinfo({ payload, callback }, { call }) {
      const response = yield call(addgoodsinfo, payload) || {};
      if (callback) callback(response);
    },
  },

  reducers: {
    fetch(state, { payload }) {
      const { list, pagination, productSearch, brands, color } = payload || {};
      return {
        ...state,
        productlistData: { list, pagination },
        productSearch,
        brandsSearch: brands,
        colorSearch: color,
      };
    },

    relaGoods(state, { payload }) {
      const { data = [] } = payload || {};
      return {
        ...state,
        relaGoods: data,
      };
    },

    finddetails(state, { payload }) {
      const { data, selectedSpecValues, allSpecNameList } = payload;
      return {
        ...state,
        productdeatils: data,
        selectedSpecValues,
        allSpecNameList,
      };
    },

    updateDetails(state, { payload }) {
      return { ...state, ...payload };
    },

    paginationchange(state, { payload }) {
      const { productlistData } = state;
      let {
        productlistData: { pagination },
      } = state;
      const { current } = payload;
      pagination = { ...pagination, current };
      return { ...state, productlistData: { ...productlistData, pagination: { ...pagination } } };
    },

    saveSearchInput(state, { payload }) {
      return {
        ...state,
        inputSearch: payload.input,
      };
    },
    clearSearchInput(state) {
      return {
        ...state,
        inputSearch: undefined,
      };
    },

    saveDesign(state, { payload }) {
      const { data: { modelList = [] } = {} } = payload || {};
      return {
        ...state,
        relevanceDesign: modelList,
      };
    },
  },
};

import { postWithPrefix, getWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/homepage/list', queryParam);
}

export async function find(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/homepage/find', queryParam);
}

export async function info(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/homepage/info', queryParam);
}
export async function del(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/homepage/del', updateParam);
}

export async function update(params) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/homepage/submit', formData);
}

import { getWithPrefix, postWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('queryParam', queryParam);
  return postWithPrefix('/wholehouse/list', formData);
}

export async function find(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/wholehouse/find', queryParam);
}

export async function add(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/wholehouse/add', formData);
}

export async function update(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/wholehouse/update', formData);
}

export async function del(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/wholehouse/del', formData);
}

export async function goodsList(params = {}) {
  const queryParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('queryParam', queryParam);
  return postWithPrefix('/goodsinfo/goodsinfoselect', formData);
}

export async function fetchDesign(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/staff/designers', queryParam);
}

export async function findPropertys(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/propertybag/find', queryParam);
}

export async function fetchHousestyle(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/housestyle/selectlist', queryParam);
}

export async function joinCart(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/shoppingcart/addgoodsinfos', formData);
}

import {
  list,
  find,
  update,
  add,
  del,
  submit,
  fetchGoods,
  querycustbind,
  querygetqrcode,
  updateCust,
  updateStatus,
  submitReceipt,
  queryReceipt,
  cancleReceipt,
  exportExcel,
  findTransformInfo,
  findhaspurchase,
  exportsettle,
  updateCustomerOrderDiscount,
} from '@/services/orderAPI';

export default {
  namespace: 'order',

  state: {
    list: [],
    view: {},
    edit: {},
    goods: {
      list: [],
      totalPrice: 0,
    },
    custQrCode: '',
    showQrCode: false,
    receiptList: [],
    stores: [],
    orderGoodsList: [],
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (!response) return;
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    *fetchGoods({ payload }, { call, put }) {
      const response = yield call(fetchGoods, payload);
      if (!response) return;
      yield put({
        type: 'saveGoods',
        payload: response,
      });
    },
    *fetchView({ payload, callback }, { call, put }) {
      const { isEdit, id } = payload;
      const response = yield call(find, { id });
      if (!response) return;
      yield put({
        type: isEdit ? 'saveEdit' : 'saveView',
        payload: response,
      });
      if (callback) callback(response);
    },
    *purchase({ payload, callback }, { put }) {
      // const response = yield call(submit, payload);
      // if (!response) return;
      // if (typeof callback === 'function') callback(response);
      let customer = localStorage.getItem('customer');
      if (customer) {
        customer = JSON.parse(customer);
      } else {
        customer = {};
      }
      const { id = '' } = customer;
      yield put({
        type: 'checkBind',
        payload: { id },
        nextAction: {
          type: 'submit',
          payload,
          callback,
        },
      });
    },
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *add({ payload, callback }, { call }) {
      const response = yield call(add, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *delete({ payload, callback }, { call }) {
      const response = yield call(del, payload);
      if (!response) return;
      if (callback) callback(response);
    },
    *checkBind({ payload, nextAction }, { call, put }) {
      const response = yield call(querycustbind, payload);
      if (!response) return;
      const { data: isBind } = response;
      if (!isBind) {
        const qrData = yield call(querygetqrcode, payload);
        const { data: qrCode } = qrData;
        yield put({
          type: 'check',
          payload: { custQrCode: qrCode, showQrCode: true },
        });
      } else {
        yield put(nextAction);
      }
    },
    *hideQrCode({ callback }, { put }) {
      if (callback) callback();
      yield put({
        type: 'hide',
        payload: { showQrCode: false },
      });
    },
    *updateCustomerInfo({ payload, callback }, { call }) {
      const response = yield call(updateCust, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *updateChangeStatus({ payload, callback }, { call }) {
      const response = yield call(updateStatus, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *submitreceipt({ payload, callback }, { call }) {
      const response = yield call(submitReceipt, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *fetchReceipt({ payload, callback }, { call, put }) {
      const response = yield call(queryReceipt, payload) || {};
      const { data: { modelList = [] } = {} } = response;
      if (callback) callback();
      yield put({
        type: 'fetchRecList',
        payload: modelList,
      });
    },
    *updateCustomerOrderDiscount({ payload, callback }, { call }) {
      const response = yield call(updateCustomerOrderDiscount, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *cancleReceiptOrder({ payload, callback }, { call }) {
      const response = yield call(cancleReceipt, payload) || {};
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },

    *orderExport(
      {
        payload: { id },
        callback,
      },
      { call }
    ) {
      const response = yield call(exportExcel, { id });
      if (!response) return;
      if (callback) callback(response);
    },
    *orderAdd({ payload, callback }, { call, select }) {
      const { orderGoodsList = [] } = yield select(state => state.order);
      const orderGoodsInfos = [];
      for (let i = 0; i < orderGoodsList.length; i++) {
        const item = orderGoodsList[i];
        const {
          goodsInfoNum,
          goodsInfo: { id: goodsInfoId },
        } = item;
        const obj = { goodsInfoNum, goodsInfoId };
        orderGoodsInfos.push(obj);
      }
      const response = yield call(submit, { ...payload, orderGoodsInfos });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *orderUpdate({ payload, callback }, { call, select }) {
      const { orderGoodsList = [] } = yield select(state => state.order);
      const orderGoodsInfos = [];
      for (let i = 0; i < orderGoodsList.length; i++) {
        const item = orderGoodsList[i];
        const {
          goodsInfoNum,
          goodsInfo: { id: goodsInfoId },
        } = item;
        const obj = { goodsInfoNum, goodsInfoId };
        orderGoodsInfos.push(obj);
      }
      const response = yield call(updateCust, { ...payload, orderGoodsInfos });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *fetchTransformView(
      {
        payload: { designId },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(findTransformInfo, { designId });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
      yield put({
        type: 'saveTransformEdit',
        payload: response,
      });
    },
    *fetchHasPurchase({ payload, callback }, { call }) {
      const response = yield call(findhaspurchase, { ...payload });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *orderExportSettle(
      {
        payload: { id },
        callback,
      },
      { call }
    ) {
      const response = yield call(exportsettle, { id });
      if (!response) return;
      if (callback) callback(response);
    },
  },

  reducers: {
    saveList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        list: data,
      };
    },
    saveGoods(state, action) {
      const { goods } = state;
      let data;
      if (action.payload) data = action.payload.data;
      const { orderGoodsInfos = [], orderPrePrice } = data;
      return {
        ...state,
        goods: { ...goods, list: orderGoodsInfos, totalPrice: orderPrePrice },
      };
    },
    saveView(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        view: data,
      };
    },
    check(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    hide(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    fetchRecList(state, { payload }) {
      return {
        ...state,
        receiptList: payload,
      };
    },
    saveStoresList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        stores: data,
      };
    },
    orderEditEmpty(state) {
      return {
        ...state,
        edit: {},
        orderGoodsList: [],
      };
    },
    saveEdit(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      const { orderGoodsInfos = [] } = data;
      return {
        ...state,
        edit: data,
        orderGoodsList: orderGoodsInfos,
      };
    },
    saveOrderGoodsList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      const { orderGoodsList } = state;
      const {
        goodsInfo: { id },
      } = data;
      const hasItem = orderGoodsList.find(key => id === key.goodsInfo.id);
      if (!hasItem) {
        orderGoodsList.push(data);
      } else {
        for (let i = 0; i < orderGoodsList.length; i++) {
          const {
            goodsInfo: { id: goodsInfoId },
          } = orderGoodsList[i];
          if (goodsInfoId === id) {
            orderGoodsList[i].goodsInfoNum += 1;
          }
        }
      }
      const newOrderGoodsList = orderGoodsList.map(v => v);
      return {
        ...state,
        orderGoodsList: newOrderGoodsList,
      };
    },
    deletePurchaseGoods(state, action) {
      let data;
      if (action.payload) data = action.payload;
      const { orderGoodsList } = state;
      const {
        goodsInfo: { id },
      } = data;
      const newArr = [];
      for (let i = 0; i < orderGoodsList.length; i++) {
        const {
          goodsInfo: { id: goodsInfoId },
        } = orderGoodsList[i];
        if (goodsInfoId !== id) {
          newArr.push(orderGoodsList[i]);
        }
      }
      return {
        ...state,
        orderGoodsList: newArr,
      };
    },
    editGoodsNum(state, action) {
      const {
        payload: { num, id },
      } = action;
      const { orderGoodsList } = state;
      for (let i = 0; i < orderGoodsList.length; i++) {
        const {
          goodsInfo: { id: goodsInfoId },
        } = orderGoodsList[i];
        if (goodsInfoId === id) {
          orderGoodsList[i].goodsInfoNum = num;
        }
      }
      const newOrderGoodsList = orderGoodsList.map(v => v);
      return {
        ...state,
        orderGoodsList: newOrderGoodsList,
      };
    },
    saveTransformEdit(state, action = {}) {
      const { payload: { data } = {} } = action;
      const { orderGoodsInfos = [] } = data;
      return {
        ...state,
        edit: data,
        orderGoodsList: orderGoodsInfos,
      };
    },
  },
};

import { submit } from '@/services/Designer/SettleMentAPI';
import { findInfo, getWXPayQrcode, getAliPayQrcode, askPayRes } from '@/services/purchaseOrderAPI';

export default {
  namespace: 'settlement',
  state: {
    settlementlist: [],
    view: {},
  },

  effects: {
    *submit({ payload, callback }, { call }) {
      const response = yield call(submit, { ...payload });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *fetchView({ payload, callback }, { call, put }) {
      const response = yield call(findInfo, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveView',
        payload: response,
      });
    },
    *getQrCode({ payload, callback }, { call }) {
      const { srcId, amount, srcType, paymentType } = payload;
      const response = yield call(paymentType === 'wx' ? getWXPayQrcode : getAliPayQrcode, {
        srcId,
        amount,
        srcType,
      });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *askPay({ payload, callback }, { call }) {
      const response = yield call(askPayRes, payload) || {};
      if (callback) callback(response);
    },
  },

  reducers: {
    settlement(state, { payload }) {
      const { data = [] } = payload;
      return {
        ...state,
        settlementlist: data,
      };
    },
    saveShopCartCount(state, { payload }) {
      return {
        ...state,
        shopCartCount: payload,
      };
    },
    saveView(state, { payload }) {
      let data;
      if (payload) data = payload.data;
      return {
        ...state,
        view: data,
      };
    },
    changeProductNum(state, { payload }) {
      const { settlementlist } = state;
      const { id, num } = payload;
      const changeItem = settlementlist.find(v => id === v.goodsInfo.id);
      changeItem.qty = num;
      const newSettlementlist = settlementlist.map(v => v);
      return {
        ...state,
        settlementlist: newSettlementlist,
      };
    },
  },
};

import { info, find } from '@/services/Designer/WebSetAPI';
import { list } from '@/services/Designer/InformationAPI';
import { brandlist } from '@/services/brandAPI';

export default {
  namespace: 'designercompage',
  state: {
    viewData: {},
    list: {},
    brands: [],
  },

  effects: {
    *fetchList(_, { call, put }) {
      const response = yield call(list, {
        page: 1,
        pageSize: 4,
        category: 'announcement',
      });
      if (!response) return;
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    *fetchView({ payload }, { call, put }) {
      const { id, pageType } = payload;
      const response = pageType ? yield call(info, { pageType }) : yield call(find, { id });
      if (!response) return;
      yield put({
        type: 'saveData',
        payload: response,
      });
    },
    *fetchBrands({ payload, callback }, { call, put }) {
      const response = yield call(brandlist, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveBrands',
        payload: response,
      });
    },
  },

  reducers: {
    saveList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        list: data,
      };
    },
    saveData(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        viewData: data,
      };
    },
    saveBrands(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        brands: data,
      };
    },
  },
};

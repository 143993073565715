import { getWithPrefix, postWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/composition/list', queryParam);
}

export async function find(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/composition/find', queryParam);
}

export async function add(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/composition/add', formData);
}

export async function update(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/composition/update', formData);
}

export async function del(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/composition/del', formData);
}

export async function fetchDesign(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/staff/designers', queryParam);
}

export async function fetchHousestyle(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/housestyle/selectlist', queryParam);
}

export async function fetchLabels(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/composition/labels', queryParam);
}

import { get, post } from '@/utils/request';

export async function addgoodsinfo(params) {
  const newParams = params || {};
  const updateParam = { updateParam: JSON.stringify(newParams) };
  return get('/site/shoppingcart/addgoodsinfo', updateParam);
}

export async function shopcartlist(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/shoppingcart/list', queryParam);
}

export async function updateshopcart(params) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/shoppingcart/update', formData);
}

export async function delshopcart(params) {
  const newParams = params || {};
  const updateParam = { updateParam: JSON.stringify(newParams) };
  return get('/site/shoppingcart/del', updateParam);
}

export async function cleanshopcart(params) {
  const newParams = params || {};
  const updateParam = { updateParam: JSON.stringify(newParams) };
  return get('/site/shoppingcart/clean', updateParam);
}

export async function exportshopcart(params) {
  const url = `/site/shoppingcart/listtoexcel?queryParam=${JSON.stringify(params)}`;
  window.location.href = encodeURI(url);
}

export async function exportxshshopcart(params) {
  const url = `/site/shoppingcart/listtoexceldesigner?queryParam=${JSON.stringify(params)}`;
  window.location.href = encodeURI(url);
}

export async function submitshopcart(params) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/order/ordersubmitfromshoppingcart', formData);
}

export async function shopcartcount(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/shoppingcart/countqty', queryParam);
}

export async function cleaninval(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/shoppingcart/cleanunablegoodsinfo', formData);
}

import * as postSaleAPI from '@/services/postSaleAPI';

export default {
  namespace: 'postsale',
  state: {
    list: {
      data: {},
      countData: {},
    },
    view: {},
    edit: {},
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(postSaleAPI.list, payload);
      if (!response) return;
      const { data } = response;
      yield put({
        type: 'saveList',
        payload: { data },
      });
    },
    *fetchCount({ payload }, { call, put }) {
      const { type, ...others } = payload;
      const response = yield call(postSaleAPI.count, { ...others });
      if (!response) return;
      const { data } = response;
      yield put({
        type: 'saveCount',
        payload: { [type]: data },
      });
    },
    *fetchView({ payload, callback }, { call, put }) {
      const { id } = payload;
      const response = yield call(postSaleAPI.findInfo, { id });
      if (!response) return;
      yield put({
        type: 'saveView',
        payload: response,
      });
      if (callback) callback(response);
    },
    *bossDeal({ payload, callback }, { call, put }) {
      const { data, id } = payload;
      const response = yield call(postSaleAPI.bossDealApi, { postSaleId: id, ...data });
      if (!response) return;
      yield put({
        type: 'fetchView',
        payload: { id },
      });
      if (callback) callback(response);
    },
    *siteDeal({ payload, callback }, { call, put }) {
      const { data, id } = payload;
      const response = yield call(postSaleAPI.siteDealApi, { postSaleId: id, ...data });
      if (!response) return;
      yield put({
        type: 'fetchView',
        payload: { id },
      });
      if (callback) callback(response);
    },
    *postSaleAdd({ payload, callback }, { call }) {
      const response = yield call(postSaleAPI.add, { ...payload });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    *bossCancelDeal({ payload, callback }, { call }) {
      const response = yield call(postSaleAPI.bossDealCancel, payload);
      if (!response) return;
      if (callback) callback(response);
    },
  },

  reducers: {
    saveList(state, { payload }) {
      const { list } = state;
      return {
        ...state,
        list: { ...list, ...payload },
      };
    },

    saveCount(state, { payload }) {
      const { list } = state;
      const { countData } = list;
      return {
        ...state,
        list: { ...list, countData: { ...countData, ...payload } },
      };
    },

    saveView(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        view: data,
      };
    },
  },
};

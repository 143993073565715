import {
  designlist,
  designRoomlist,
  buildingdesignlist,
  buildingdesign,
  typelist,
  alltypelist,
  stylelist,
  designadd,
  builddesignadd,
  houseroomlistcustom,
  housepartlistcustom,
  designaddroompart,
  // productlist,
  // queryProductListByGoodsIdSite,
  designaddroomproduct,
  designupdateroomproduct,
  designdeleteroompartproduct,
  designdeleteroompart,
  designdeleteroom,
  adddefineroom,
  addtotemplate,
  updatebuildingdesign,
  designupdate,
  designinfo,
  findcustomer,
  creatcustdesign,
  deletedesign,
  deletecustomerdesign,
  deletebuildingdesign,
  queryallcustomerdesign,
  updatecustomer,
  newcustomerdesign,
  roomlist,
  lastdesign,
  designProductNum,
  exportCustomerDesign,
  exportTemplateDesign,
  joinCart,
} from '@/services/designAPI';
import { response2PageList } from '@/utils/requestUtils';

export default {
  namespace: 'designmodels',
  state: {
    data: {
      listview: {
        list: [], // 方案库列表
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
      },
      custlistview: {
        list: [], // 客户方案列表
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
      },
      designinfodata: {
        designinfo: {}, // 方案数据
        roomData: [], // 空间
        building: {},
      }, // 设计数据.详情与编辑公用
      editModel: {
        // 编辑页面
        products: {
          list: [],
          pagination: {
            current: 1,
            pageSize: 4,
            total: 0,
          },
          brothermap: {},
        },
        roomList: [], // 自定义空间
        partmap: {}, // 可选空间映射
      },
    },
    houseTypeList: [], // 户型列表
    houseStyleList: [], // 风格列表
    houseRoomList: [], // 空间方案的空间列表
    customerlist: [],
    selectStyleId: undefined,
    designSearchData: {},
    roomDesignSearchData: {},
    productNum: 0,
    lastDesign: {},
    selectSearchData: [],
  },

  effects: {
    *fetchStoreList({ payload = {} }, { call, put }) {
      const { pageNo: current = 1, pageSize, viewType, ...others } = payload;
      // let response;
      let url = designlist;
      if (viewType === 'roomdesign') {
        url = designRoomlist;
      } else if (viewType === 'estatedesign') {
        url = buildingdesignlist;
      }
      const response = yield call(url, {
        page: current,
        pageSize,
        ...others,
      });

      const { list: listData, pagination } = response2PageList(
        { current, pageSize, viewType, ...others },
        response
      );

      yield put({
        type: 'fetch',
        payload: { list: listData, pagination },
      });
    },

    *fetchallcustdesign({ payload = {} }, { call, put }) {
      const { pageNo: current = 1, pageSize, ...others } = payload;
      const defaultParam = { page: current, pageSize, ...others };
      // if (needProductNum) {
      //   defaultParam = { page, pageSize: 999, ...others };
      // }
      const response = yield call(queryallcustomerdesign, defaultParam);

      const { list: listData, pagination } = response2PageList(
        { current, pageSize, ...others },
        response
      );

      // if (needProductNum) {
      //   let productNum = 0;
      //   for (let i = 0; i < listData.length; i++) {
      //     const listItem = listData[i] || {};
      //     const custRooms = listItem.rooms || [];
      //     for (let j = 0; j < custRooms.length; j++) {
      //       const roomItem = custRooms[j] || {};
      //       const partsList = roomItem.parts || [];
      //       for (let k = 0; k < partsList.length; k++) {
      //         const partsItem = partsList[k] || {};
      //         const { products = [] } = partsItem;
      //         productNum += products.length;
      //       }
      //     }
      //   }
      //   yield put({
      //     type: 'fetchProNum',
      //     payload: { productNum },
      //   });
      // } else {
      yield put({
        type: 'querycustdesign',
        payload: { list: listData, pagination },
      });
      // }
    },

    *fetchHouseType({ payload = {} }, { call, put }) {
      const { isRoom } = payload;
      const response = yield call(isRoom ? alltypelist : typelist);
      const typedata = get(response, 'data') || [];
      yield put({
        type: 'queryHouseTypeHandle',
        payload: typedata,
      });
    },

    *fetchHouseStyle(_, { call, put }) {
      const response = yield call(stylelist);
      const styledata = get(response, 'data') || [];
      yield put({
        type: 'queryHouseStyleHandle',
        payload: styledata,
      });
    },

    *fetchHouseRoom(_, { call, put }) {
      const response = yield call(roomlist);
      const styledata = get(response, 'data') || [];
      yield put({
        type: 'queryHouseRoomHandle',
        payload: styledata,
      });
    },

    *fetchdesign({ payload = {}, callback, isEdit }, { call, put }) {
      const { viewType } = payload;
      let url = designinfo;
      if (viewType === 'customerdesign') {
        url = findcustomer;
      } else if (viewType === 'estatedesign') {
        url = buildingdesign;
      }
      const response = yield call(url, payload); // 如果当前为客户方案，则使用findcustomer
      if (!response) return;
      if (callback) callback(response);
      const {
        data: { rooms = [], notGroupProducts, building },
      } = response;
      if (rooms && rooms.length > 0 && isEdit) {
        for (let i = 0; i < rooms.length; i++) {
          const roomItem = rooms[i];
          const roomId = roomItem.room.id;
          const param = { roomId };
          yield put({
            type: 'fetchhousepart',
            payload: param,
          });
        }
      }
      if (notGroupProducts && notGroupProducts.length) {
        rooms.unshift({
          id: -1,
          alias: '未分组',
          parts: [{ alias: '未分组商品', products: notGroupProducts }],
        });
      }
      yield put({
        type: 'queryDetail',
        payload: { designinfo: response.data, roomData: rooms, building },
      });
    },

    *creatcustdesign({ payload = {}, callback }, { call }) {
      const response = yield call(creatcustdesign, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *savedesign({ payload = {}, callback }, { call }) {
      const response = yield call(
        payload.viewType === 'estatedesign' ? builddesignadd : designadd,
        payload
      );
      if (!response) return;
      if (callback) callback(response);
    },

    *addtotemplate({ payload = {}, callback }, { call }) {
      const response = yield call(addtotemplate, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *designupdate({ payload = {}, callback }, { call }) {
      const { viewType } = payload;
      let url = designupdate;
      if (viewType === 'estatedesign') {
        url = updatebuildingdesign;
      } else if (viewType === 'customerdesign') {
        url = updatecustomer;
      }
      const response = yield call(url, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *fetchroomlist(_, { call, put }) {
      const response = yield call(houseroomlistcustom) || [];
      yield put({
        type: 'queryRoom',
        payload: { defineroomlist: response.data },
      });
    },

    *fetchhousepart({ payload = {} }, { call, put }) {
      const response = yield call(housepartlistcustom, payload) || [];
      // if (callback) callback(response);
      yield put({
        type: 'convertParts',
        payload: { partdata: response.data, roomId: payload.roomId },
      });
      // if (firstLoad) {
      //   yield put({
      //     type: 'queryDetail',
      //     payload: args,
      //   });
      // }
    },

    *addhousepart({ payload = {}, callback }, { call }) {
      const response = yield call(designaddroompart, payload) || {};
      if (callback) callback(response);
    },

    *addhousepartcb({ payload }, { put, select }) {
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      const { roomKey, resultObject: resdata } = payload;
      for (let i = 0; i < currentRoomData.length; i++) {
        const currentRoom = currentRoomData[i];
        if (i === roomKey) {
          let { parts } = currentRoom;
          if (!parts) parts = [];
          parts.push(resdata);
          currentRoomData[i].parts = parts;
        }
      }
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    // *fetchproduct({ payload = {}, callback }, { call, put, select }) {
    //   const { pageNo, ...others } = payload;
    //   const response = yield call(productlist, { pageNo, ...others });
    //   const { list: listData, pagination } = response2PageList(payload, response);
    //   pagination.current = pageNo;
    //   for (let r = 0; r < listData.length; r++) {
    //     const item = listData[r];
    //     let { openSpecVo, specVo } = item;
    //     if (!openSpecVo) openSpecVo = [];
    //     if (!specVo) specVo = [];
    //     for (let i = 0; i < openSpecVo.length; i++) {
    //       const openItem = openSpecVo[i];
    //       const openItemSpecId = get(openItem, 'specId');
    //       for (let j = 0; j < specVo.length; j++) {
    //         // const voItem = specVo[j];
    //         const voItemSpecId = get(specVo[j], 'goodsSpecDetail.specId');
    //         const specDetailId = get(specVo[j], 'goodsSpecDetail.specDetailId');
    //         if (openItemSpecId === voItemSpecId) {
    //           // set(openItem, 'specDetaultDetailId', voItemSpecId);
    //           openItem.specDetaultDetailId = specDetailId;
    //         }
    //       }
    //     }
    //   }
    //   if (callback) callback(response);
    //   // console.log(listData);
    //   const {
    //     editModel: {
    //       products: { brothermap },
    //     },
    //   } = yield select(state => state.designmodels.data);
    //   for (let i = 0; i < listData.length; i++) {
    //     const goodsId = listData[i].goodsId;
    //     const goodsIn = `goods${goodsId}`;
    //     if (!brothermap[goodsIn]) {
    //       // 不请空兄弟商品的时候，如果已经兄弟商品中已经存在当前商品的兄弟商品，则不重新加载兄弟
    //       yield put({
    //         type: 'fetchbrother',
    //         payload: goodsId,
    //       });
    //     }
    //   }
    //   yield put({
    //     type: 'queryProduct',
    //     payload: { list: listData, pagination },
    //   });
    // },

    // *fetchbrother({ payload }, { call, put }) {
    //   const response = yield call(queryProductListByGoodsIdSite, payload);
    //   // if (callback) callback(response);
    //   const { data } = response;
    //   const convertBrotherItem = item => {
    //     let openSpecVo = get(item, 'openSpecVo');
    //     let specVo = get(item, 'specVo');
    //     if (!openSpecVo) openSpecVo = [];
    //     if (!specVo) specVo = [];
    //     for (let i = 0; i < openSpecVo.length; i++) {
    //       const openItem = openSpecVo[i];
    //       const openItemSpecId = get(openItem, 'specId');
    //       for (let j = 0; j < specVo.length; j++) {
    //         // let voItem = specVo[j];
    //         const voItemSpecId = get(specVo[j], 'goodsSpecDetail.specId');
    //         const goodsSpecDetail = get(specVo[j], 'goodsSpecDetail');
    //         const specDetailId = get(specVo[j], 'goodsSpecDetail.specDetailId');
    //         if (openItemSpecId === voItemSpecId) {
    //           // this.set(openItem, 'goodsSpecDetail', goodsSpecDetail);
    //           openItem.goodsSpecDetail = goodsSpecDetail;
    //           openItem.specDetaultDetailId = specDetailId;
    //         }
    //       }
    //     }
    //     return item;
    //   };
    //   const brotherItem = data || [];
    //   for (let i = 0; i < brotherItem.length; i++) {
    //     const brotherDataItem = convertBrotherItem(brotherItem[i]);
    //     brotherItem[i].goodsInfoName = brotherDataItem.productName;
    //   }
    //   yield put({
    //     type: 'brotherGoods',
    //     payload: { brotherdata: response.data, goodsId: payload },
    //   });
    // },

    *addproducttoorder({ payload, callback }, { call, put }) {
      const response = yield call(designaddroomproduct, payload);
      const { updateProductNum } = payload;
      if (updateProductNum) {
        yield put({
          type: 'fetchProNum',
        });
      }
      if (callback) callback(response);
    },

    *fetchProNum(_, { call, put }) {
      const customerObj = JSON.parse(localStorage.getItem('customer')) || {};
      const { id: customerId } = customerObj;
      const response = yield call(designProductNum, { customerId }) || {};
      yield put({
        type: 'updateProNum',
        payload: response.data || 0,
      });
    },

    *addProductCb(
      {
        payload: { resultObject: productCbdata, roomKey, designRoomPartId: partIndex },
      },
      { put, select }
    ) {
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      const { goodsInfo: { id: pid } = {}, number: pnumber } = productCbdata;
      for (let i = 0; i < currentRoomData.length; i++) {
        const roomItem = currentRoomData[i];
        const parts = roomItem.parts || [];
        if (roomKey === i) {
          for (let j = 0; j < parts.length; j++) {
            const partItem = parts[j];
            if (j === partIndex) {
              let { products } = parts[j];
              if (!products) {
                products = [];
                parts[j].products = [];
              }
              let okFlag = false;
              for (let k = 0; k < products.length; k++) {
                const pitem = products[k] || {};
                const {
                  goodsInfo: { id: pItemId },
                } = pitem;
                if (pItemId === pid) {
                  okFlag = true;
                  // products[k].number = pnumber;
                  products[k] = { ...pitem, number: pnumber };
                  // parts[j].products = products;
                  parts[j] = { ...partItem, products };
                  // currentRoomData[i].parts = parts;
                  currentRoomData[i] = { ...roomItem, parts };
                  break;
                }
              }
              if (!okFlag) {
                parts[j].products.push(productCbdata);
                parts[j] = { ...parts[j] };
                // currentRoomData[i].parts = parts;
                currentRoomData[i] = { ...roomItem, parts };
                break;
              }
            }
          }
        }
      }
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    *changepronum({ payload, callback }, { call }) {
      const response = yield call(designupdateroomproduct, payload);
      if (callback) callback(response);
    },

    *moveproductCb(
      {
        payload: { resultObject: productCbdata },
      },
      { put, select }
    ) {
      // 移动产品
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      const { id: pid, number: pnumber, designRoomPartId } = productCbdata;
      // let okFlag = false;
      for (let i = 0; i < currentRoomData.length; i++) {
        // if (okFlag) break;
        const roomItem = currentRoomData[i];
        let { parts } = roomItem;
        if (!parts) parts = [];
        for (let j = 0; j < parts.length; j++) {
          // if (okFlag) break;
          const partItem = parts[j];
          if (partItem.id === designRoomPartId) {
            const { products } = partItem;
            if (!products) {
              parts[j].products = [];
            }
            let okFlag = false;
            for (let k = 0; k < products.length; k++) {
              const pitem = products[k] || {};
              if (pitem.id === pid) {
                okFlag = true;
                // products[k].number = pnumber;
                products[k] = { ...pitem, number: pnumber + products[k].number };
                parts[j] = { ...partItem, products };
                currentRoomData[i] = { ...roomItem, parts };
                break;
              }
            }
            if (!okFlag) {
              parts[j].products.push(productCbdata);
              parts[j] = { ...parts[j] };
              break;
            }
          }
        }
      }
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    *changepronumcb(
      {
        payload: { productId, partIndex, roomKey, resultObject: productdata },
      },
      { put, select }
    ) {
      // 改变配置单产品数量
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      let okFlag = false;
      for (let i = 0; i < currentRoomData.length; i++) {
        if (okFlag) break;
        const room = currentRoomData[i];
        if (i === roomKey) {
          let { parts } = room;
          if (!parts) parts = [];
          for (let j = 0; j < parts.length; j++) {
            if (okFlag) break;
            const part = parts[j];
            if (j === partIndex) {
              let { products } = part;
              if (!products) products = [];
              for (let k = 0; k < products.length; k++) {
                const pItem = products[k];
                if (pItem.id === productId) {
                  products[k] = { ...products[k], number: productdata.number };
                  parts[j] = { ...parts[j], products };
                  currentRoomData[i] = { ...currentRoomData[i], parts };
                  okFlag = true;
                  break;
                }
              }
            }
          }
        }
      }
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    *delproduct({ payload, callback }, { call }) {
      const response = yield call(designdeleteroompartproduct, payload);
      if (callback) callback(response);
    },

    *delproductcb(
      {
        payload: { delPartIndex, delRoomIndex, delId },
      },
      { put, select }
    ) {
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      let okFlag = false;
      for (let i = 0; i < currentRoomData.length; i++) {
        if (okFlag) break;
        const room = currentRoomData[i];
        if (i === delRoomIndex) {
          let { parts } = room;
          if (!parts) parts = [];
          for (let j = 0; j < parts.length; j++) {
            if (okFlag) break;
            const part = parts[j];
            if (j === delPartIndex) {
              const { products } = part;
              const newProduct = products.filter(item => item.id !== delId);
              // parts[j].products = newProduct;
              parts[j] = { ...part, products: newProduct };
              // currentRoomData[i].parts=parts;
              currentRoomData[i] = { ...room, parts };
              okFlag = true;
              break;
            }
          }
        }
      }
      // console.log(currentRoomData);
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    *delpart({ payload, callback }, { call }) {
      const response = yield call(designdeleteroompart, payload);
      if (callback) callback(response);
    },

    *delpartcb(
      {
        payload: { delPartIndex, delRoomKey },
      },
      { put, select }
    ) {
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      for (let i = 0; i < currentRoomData.length; i++) {
        const room = currentRoomData[i];
        if (i === delRoomKey) {
          const { parts } = room;
          const newpart = parts.filter((item, index) => index !== delPartIndex);
          currentRoomData[i] = { ...room, parts: newpart };
          break;
        }
      }
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    *delroom({ payload, callback }, { call }) {
      const response = yield call(designdeleteroom, payload);
      if (callback) callback(response);
    },

    *delroomcb({ payload: roomKey }, { put, select }) {
      const {
        designinfodata: { roomData: currentRoomData },
      } = yield select(state => state.designmodels.data);
      const newRoomData = currentRoomData.filter((item, index) => index !== roomKey); // 剔除当前已删除空间
      yield put({
        type: 'reloadRoom',
        payload: newRoomData,
      });
    },

    *adddefineroom({ payload, callback }, { call }) {
      const response = yield call(adddefineroom, payload);
      if (callback) callback(response);
    },

    *adddefineroomcb({ payload }, { put, select }) {
      const {
        designinfodata: { roomData: currentRoomData },
        editModel: { partmap },
      } = yield select(state => state.designmodels.data);
      currentRoomData.push(payload);
      const roomId = get(payload, 'room.id');
      const roomIn = `room${roomId}`;
      if (!partmap[roomIn]) {
        yield put({
          type: 'fetchhousepart',
          payload: { roomId },
        });
      }
      yield put({
        type: 'reloadRoom',
        payload: currentRoomData,
      });
    },

    *deletedesign({ payload = {}, callback }, { call }) {
      const { viewType } = payload;
      let url = deletedesign;
      if (viewType === 'customerdesign') {
        url = deletecustomerdesign;
      } else if (viewType === 'estatedesign') {
        url = deletebuildingdesign;
      }
      const response = yield call(url, payload.id);
      if (callback) callback(response);
    },

    *newcustomerdesign({ payload = {}, callback }, { call }) {
      const response = yield call(newcustomerdesign, payload);
      if (callback) callback(response);
    },

    *fetchlastdesign({ payload = {} }, { call, put }) {
      const response = yield call(lastdesign, payload) || {};
      const { data } = response;
      yield put({
        type: 'setlastdesign',
        payload: data,
      });
    },

    *exportdesign({ payload = {} }, { call }) {
      const { isTempalate } = payload;
      yield call(isTempalate ? exportTemplateDesign : exportCustomerDesign, payload) || {};
    },

    *joinCart({ payload, callback }, { call }) {
      const response = yield call(joinCart, payload);
      if (!response) return;
      if (callback) callback(response);
    },
  },
  reducers: {
    fetch(state, action) {
      const currentState = state;
      let { data: listview } = currentState;
      const { data } = currentState;
      listview = action.payload || {};
      return {
        ...state,
        data: { ...data, listview },
      };
    },

    querycustdesign(state, action) {
      const currentState = state;
      let { data: custlistview } = currentState;
      const { data } = currentState;
      custlistview = action.payload || {};
      return {
        ...state,
        data: { ...data, custlistview },
      };
    },

    updateProNum(state, { payload }) {
      return {
        ...state,
        productNum: payload,
      };
    },

    queryHouseTypeHandle(state, action) {
      return {
        ...state,
        houseTypeList: action.payload,
      };
    },
    queryHouseStyleHandle(state, action) {
      return {
        ...state,
        houseStyleList: action.payload,
      };
    },
    queryHouseRoomHandle(state, action) {
      return {
        ...state,
        houseRoomList: action.payload,
      };
    },

    queryDetail(
      state,
      {
        payload: { designinfo: designinfoData, roomData, building },
      }
    ) {
      const {
        data,
        data: { designinfodata },
      } = state;
      return {
        ...state,
        data: {
          ...data,
          designinfodata: { ...designinfodata, designinfo: designinfoData, roomData, building },
        },
      };
    },

    queryRoom(
      state,
      {
        payload: { defineroomlist },
      }
    ) {
      const {
        data,
        data: { editModel },
      } = state;
      return {
        ...state,
        data: { ...data, editModel: { ...editModel, roomList: defineroomlist } },
      };
    },

    queryProduct(
      state,
      {
        payload: { list, pagination },
      }
    ) {
      const {
        data,
        data: {
          editModel,
          editModel: { products },
        },
      } = state;
      return {
        ...state,
        data: { ...data, editModel: { ...editModel, products: { ...products, list, pagination } } },
      };
    },

    convertParts(
      state,
      {
        payload: { partdata, roomId },
      }
    ) {
      const {
        data,
        data: {
          editModel,
          editModel: { partmap },
        },
      } = state;
      const roomIn = `room${roomId}`;
      if (!partmap[roomIn]) {
        partmap[roomIn] = partdata;
      }
      return {
        ...state,
        data: { ...data, editModel: { ...editModel, partmap } },
      };
    },

    brotherGoods(
      state,
      {
        payload: { brotherdata, goodsId },
      }
    ) {
      const {
        data,
        data: {
          editModel,
          editModel: {
            products,
            products: { brothermap },
          },
        },
      } = state;
      const goodsIn = `goods${goodsId}`;
      if (!brothermap[goodsIn]) {
        brothermap[goodsIn] = brotherdata;
      }

      return {
        ...state,
        data: { ...data, editModel: { ...editModel, products: { ...products, brothermap } } },
      };
    },

    reloadRoom(state, { payload: currentRoomData }) {
      const {
        data,
        data: { designinfodata },
      } = state;
      return {
        ...state,
        data: { ...data, designinfodata: { ...designinfodata, roomData: currentRoomData } },
      };
    },
    pitchStyleClassify(state, { payload }) {
      return {
        ...state,
        selectStyleId: payload.selectId,
      };
    },
    saveDesignSearch(state, { payload }) {
      return {
        ...state,
        designSearchData: { ...payload },
      };
    },

    saveRoomDesignSearch(state, { payload }) {
      return {
        ...state,
        roomDesignSearchData: { ...payload },
      };
    },

    setlastdesign(state, { payload }) {
      return {
        ...state,
        lastDesign: { ...payload },
      };
    },

    setSearchData(state, { payload }) {
      return {
        ...state,
        selectSearchData: payload,
      };
    },

    paginationchange(state, { payload }) {
      const {
        data: { listview },
        data,
      } = state;
      // console.log(listview);
      let { pagination } = listview;
      const { current } = payload;
      pagination = { ...pagination, current };
      return {
        ...state,
        data: { ...data, listview: { ...listview, pagination: { ...pagination, current } } },
      };
      // return { ...state, productlistData: { ...productlistData, pagination: { ...pagination } } };
    },
  },
};

import { list, content, update, info, del } from '@/services/HomepageAPI';
import { fetchList } from '@/services/org';
import { goodsCount } from '@/services/productAPI';
import * as goodsAPI from '@/services/goodsAPI';
import * as PackagedMealAPI from '@/services/PackagedMealAPI';

export default {
  namespace: 'homepage',

  state: {
    contect: '',
    list: {},
    orgs: [],
    homePageFloors: [],
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (!response) return;
      yield put({
        type: 'saveList',
        payload: response,
      });
    },

    *fetchContent({ payload, callback }, { call, put }) {
      const { id } = payload;
      const response = yield call(id ? content : info, payload);
      if (!response) return;
      if (callback) callback(response);
      const { data } = response;

      const { data: { homePageFloors = [] } = {} } = response;
      yield put({
        type: 'saveFloor',
        payload: { homePageFloors },
      });
      yield put({
        type: 'saveContent',
        payload: data,
      });
    },

    *fetchOrgsPage({ payload }, { put, call }) {
      const response = yield call(fetchList, payload);
      if (!response) return;
      yield put({
        type: 'saveOrgsPage',
        payload: response,
      });
    },

    *update({ payload, callback }, { call }) {
      const response = yield call(update, payload);
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },
    // *add({ payload, callback }, { call }) {
    //   const response = yield call(add, payload);
    //   if (!response) return;
    //   if (typeof callback === 'function') callback(response);
    // },
    *delete(
      {
        payload: { id },
        callback,
      },
      { call }
    ) {
      const response = yield call(del, { id });
      if (!response) return;
      if (typeof callback === 'function') callback(response);
    },

    *fetchCount({ payload, callback }, { call, put }) {
      const response = yield call(goodsCount, { ...payload });
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveCount',
        payload: response,
      });
    },
    *fetchGoodsList({ payload, callback }, { call }) {
      const response = yield call(goodsAPI.list, payload);
      if (!response) return;
      if (callback) callback(response);
    },
    *fetchDesignView({ payload, callback }, { call }) {
      const response = yield call(PackagedMealAPI.find, payload);
      if (!response) return;
      if (callback) callback(response);
    },
  },

  reducers: {
    saveList(state, action) {
      const { payload: { data } = {} } = action;
      return {
        ...state,
        list: data,
      };
    },
    saveOrgsPage(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      const { modelList = [] } = data;
      return {
        ...state,
        orgs: modelList,
      };
    },
    saveContent(state, { payload }) {
      return {
        ...state,
        contect: payload,
      };
    },
    addFloor(state, { payload }) {
      const { homePageFloors } = state;
      homePageFloors.push({ ...payload });
      return {
        ...state,
        homePageFloors,
      };
    },
    saveFloor(state, { payload }) {
      const { homePageFloors } = payload;
      return {
        ...state,
        homePageFloors,
      };
    },
  },
};

import { getWithPrefix, postWithPrefix } from '@/utils/request';

export async function productlist(param = {}) {
  return getWithPrefix('/goods/list', { queryParam: JSON.stringify(param) });
}

export async function findproduct(param = {}) {
  return getWithPrefix('/goods/find', { queryParam: JSON.stringify(param) });
}

export async function addProduct(param = {}) {
  const queryParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', queryParam);
  return postWithPrefix('/goods/add', formData);
}

export async function updateProduct(param = {}) {
  const queryParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', queryParam);
  return postWithPrefix('/goods/update', formData);
}

export async function publishGoods(param = {}) {
  return getWithPrefix('/goods/publish', { updateParam: JSON.stringify(param) });
}

export async function republishGoods(param = {}) {
  return getWithPrefix('/goods/unpublish', { updateParam: JSON.stringify(param) });
}

export async function delGoods(param = {}) {
  return getWithPrefix('/goods/del', { updateParam: JSON.stringify(param) });
}

export async function applyGoods(param = {}) {
  return getWithPrefix('/goods/appomint', { queryParam: JSON.stringify(param) });
}

export async function fetchApplyOrder(param = {}) {
  return getWithPrefix('/appointmentorder/list', { queryParam: JSON.stringify(param) });
}

export async function dispose(param = {}) {
  return getWithPrefix('/appointmentorder/handel', { updateParam: JSON.stringify(param) });
}

export default [
  // user
  {
    path: '/user',
    name: 'user',
    component: '../layouts/UserLayout',
    routes: [
      { path: '/user', redirect: '/user/login' },
      { path: '/user/login', name: 'login', component: './User/NewLogin' },
      { path: '/user/register', name: 'register', component: './User/Register' },
      { path: '/user/modpwd', name: 'modpwd', component: './User/ModPwd' },
      { path: '/user/register-result', component: './User/RegisterResult' },
    ],
  },
  // app
  {
    path: '/',
    component: '../layouts/BasicLayout',
    Routes: ['src/pages/Authorized'],
    // authority: ['admin', 'user'],
    // needAuth: true,
    routes: [
      // dashboard
      { path: '/', redirect: '/homepage' },
      // 搞事情
      {
        path: '/homepage',
        icon: 'icon-Home',
        name: 'homepage',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/homepage',
            redirect: '/homepage/view',
          },
          {
            path: '/homepage/view',
            name: 'homepageview',
            component: './Site/HomePage/HomePage',
          },
          {
            path: '/homepage/view/productview/:id',
            name: 'view',
            component: './Site/HomePage/ProductView',
          },
        ],
      },
      {
        name: 'account',
        icon: 'icon-zhanghu1',
        path: '/account',
        routes: [
          {
            path: '/account/account',
            name: 'account',
            icon: 'icon-zhanghu5',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/account/account',
                redirect: '/account/account/list',
              },
              {
                path: '/account/account/list',
                name: 'list',
                component: './Boss/Account/AccountList',
              },
              {
                path: '/account/account/view/:id',
                name: 'view',
                component: './Boss/Account/AccountView',
              },
            ],
          },
          {
            path: '/account/businessorder',
            name: 'businessorder',
            hideChildrenInMenu: true,
            icon: 'icon-qiyexinxi2',
            routes: [
              {
                path: '/account/businessorder',
                redirect: '/account/businessorder/list',
              },
              {
                path: '/account/businessorder/list',
                name: 'list',
                component: './Boss/Professional/ProfessionalChild',
              },
            ],
          },
        ],
      },
      {
        name: 'notification',
        icon: 'icon-xiaoxiguanli',
        path: '/notification',
        routes: [
          {
            path: '/notification/mynotice',
            name: 'mynotice',
            icon: 'icon-xiaoxi',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/notification/mynotice',
                redirect: '/notification/mynotice/list',
              },
              {
                path: '/notification/mynotice/list',
                name: 'list',
                component: './Notification/NotificationList',
              },
            ],
          },
        ],
      },
      {
        name: 'site',
        icon: 'icon-jichu',
        path: '/basic',
        routes: [
          {
            path: '/basic/org',
            name: 'org',
            component: './Site/Org/OrgInfo',
            needCtrlChidrenBtn: true,
            hideChildrenInMenu: true,
            icon: 'icon-qiyexinxi1',
            routes: [
              {
                path: '/basic/org',
                redirect: '/basic/org/department',
              },
              {
                path: '/basic/org/department',
                name: 'info',
                btns: [
                  { key: 'edit', label: '修改' },
                  { key: 'dealPwd', label: '交易密码' },
                ],
                component: './Site/Org/Department',
              },
              {
                path: '/basic/org/paypwd',
                name: 'pwd',
                component: './Site/Org/DealPwd',
              },
              // {
              //   path: '/basic/org/invitedorg',
              //   name: 'invitedorg',
              //   component: './Site/Org/InvitedOrg',
              //   btns: [{ key: 'view', label: '查看' }],
              // },
              // {
              //   path: '/basic/org/bindingbank',
              //   component: './Site/BalanceWithdraw/BindingBank',
              // },
            ],
          },
          {
            path: '/basic/role',
            name: 'role',
            icon: 'icon-icon_jiaosexinxiguanli',
            btns: [{ key: 'edit', label: '修改' }],
            component: './Site/Role/RoleList',
          },
          {
            path: '/basic/settings',
            name: 'settings',
            component: './Account/Settings/Info',
            icon: 'icon-gerenshezhi2',
            btns: [{ key: 'edit', label: '修改' }],
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/basic/settings',
                redirect: '/basic/settings/personal',
              },
              {
                path: '/basic/settings/personal',
                name: 'personal',
                component: './Account/Settings/PersonalView',
                btns: [{ key: 'sendInvite', label: '邀请' }],
              },
              {
                path: '/basic/settings/invitedorg',
                name: 'invitedorg2',
                component: './Site/Org/InvitedOrg',
                btns: [{ key: 'view', label: '查看' }],
                needAuth: true,
              },
              {
                path: '/basic/settings/security',
                component: './Account/Settings/SecurityView',
              },

              // {
              //   path: '/basic/settings/banlance',
              //   component: './Site/BalanceWithdraw/BalanceWithdraw',
              // },
              // {
              //   path: '/basic/settings/realname',
              //   component: './Site/BalanceWithdraw/RealNameView',
              // },
              // {
              //   path: '/basic/settings/bindingbank',
              //   component: './Site/BalanceWithdraw/BindingBank',
              // },

              // {
              //   path: '/basic/settings/transactionpwd',
              //   component: './Site/BalanceWithdraw/TransactionPwd',
              // },
            ],
          },
          {
            path: '/basic/journal',
            name: 'journal',
            icon: 'icon-dingdan3',
            component: './Boss/Journal/JournalList',
          },
        ],
      },
      // {
      //   path: '/appshop',
      //   name: 'appshop',
      //   icon: 'icon-yingyongshichang',
      //   component: './Site/AppShop/AppShopList',
      //   btns: [{ key: 'view', label: '查看' }],
      // },
      {
        component: '404',
        needAuth: false,
      },
    ],
  },
];

import { post, get, getWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/list', queryParam);
}

export async function findInfo(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/find', queryParam);
}

export async function findFxInfo(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/distributionorder/find', queryParam);
}

export async function addOrder(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/purchaseorder/add', formData);
}

export async function getWXPayQrcode(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/receiptpayment/paymentwxcharge', updateParam);
}

export async function getAliPayQrcode(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/receiptpayment/paymentalipaycharge', updateParam);
}

export async function askPayRes(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/pingpp/findcharge', queryParam);
}

export async function addPayOrder(params = {}) {
  // 采购订单付款单
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/receiptpayment/submitpaymentfrompurchaseorderoffline', formData);
}

// export async function updateStatus(params = {}) {
//   const updateParam = JSON.stringify(params);
//   const formData = new FormData();
//   formData.append('updateParam', updateParam);
//   return post('/boss/purchaseorder/changestatus', formData);
// }

export async function editOrder(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/purchaseorder/update', formData);
}

export async function findTransformInfo(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/order/ordertopurchaseorder', updateParam);
}

export async function transformOrder(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/purchaseorder/addfromorder', formData);
}

export async function designTransformInfo(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/purchaseorder/addfromdesign', formData);
}

export async function deleteOrder(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/purchaseorder/cancel', updateParam);
}

export async function purchasePosPay(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/receiptpayment/submitpaymentfrompurchaseorderpos', updateParam);
}
export async function audit(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/audit', updateParam);
}

export async function toCancelAudit(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/unaudit', updateParam);
}

export async function auditPass(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/auditnotpass', updateParam);
}

export async function exportExcel(params = {}) {
  const url = `http://site.pei.nature-home.cn/site/purchaseorder/purchaseordertoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

export async function XSHexportExcel(params = {}) {
  const url = `http://shop.rzxsh.com/site/purchaseorder/listtoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.location.href = encodeURI(url);
}

export async function updatePrice(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/boss/purchaseorder/updateamtorshipping', formData);
}

export async function canclePayment(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/receiptpayment/paymentcancel', updateParam);
}

export async function getcount(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/count', queryParam);
}

export async function confirm(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/confirm', updateParam);
}

export async function unconfirm(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/unconfirm', updateParam);
}

export async function confirmnotpass(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/confirmnotpass', updateParam);
}

export async function sendGoods(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/findunsendgoods', queryParam);
}

export async function deliveryorderAdd(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/boss/deliveryorder/add', formData);
}

export async function exportListExcel(params = {}) {
  const url = `http://site.pei.nature-home.cn/site/purchaseorder/listtoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

export async function bossExportExcel(params = {}) {
  const url = `http://boss.nature-home.cn/boss/purchaseorder/purchaseordertoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

export async function receiveOrder(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/purchaseorder/confirmreceipt', updateParam);
}

export async function findFxInfoXSH(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/distributionorder/findxsh', queryParam);
}

export async function goodslist(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/goodsinfolist', queryParam);
}

export async function bossCancelOrder(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/cancel', updateParam);
}

export async function usededuction(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/usebalanceandcommission', updateParam);
}

export async function findserviceamt(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/shippingservice/compute', queryParam);
}

export async function toPassCancelAudit(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/cancelauditpass', updateParam);
}

export async function toConfirmDiscount(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/confirmdiscount', updateParam);
}

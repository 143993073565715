import { list, find, findPropertys, joinCart, fetchHousestyle } from '@/services/PackagedMealAPI';
import { fetchLabels } from '@/services/worksDisplayAPI';

export default {
  namespace: 'wholeHouse',
  state: {
    list: {},
    view: {},
    property: {},
    housestyles: [],
    houseStyleId: undefined,
    labels: [],
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const response = yield call(list, payload);
      if (!response) return;
      yield put({
        type: 'saveList',
        payload: response,
      });
    },

    *fetchView({ payload, callback }, { call, put }) {
      const response = yield call(find, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveView',
        payload: response,
      });
    },

    *fetchProperty({ payload }, { call, put }) {
      const response = yield call(findPropertys, payload);
      if (!response) return;
      yield put({
        type: 'saveProperty',
        payload: response,
      });
    },

    *fetchHousestyle({ payload }, { call, put }) {
      const response = yield call(fetchHousestyle, payload);
      if (!response) return;
      yield put({
        type: 'saveHousestyle',
        payload: response,
      });
    },

    *joinCart({ payload, callback }, { call }) {
      const response = yield call(joinCart, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *fetchLabels({ payload }, { call, put }) {
      const response = yield call(fetchLabels, payload);
      if (!response) return;
      yield put({
        type: 'saveLabels',
        payload: response,
      });
    },
  },

  reducers: {
    saveList(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        list: data,
      };
    },
    saveView(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        view: data,
      };
    },
    saveProperty(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        property: data,
      };
    },
    saveHousestyle(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        housestyles: data,
      };
    },
    selectHouseStyleId(state, { payload }) {
      return {
        ...state,
        houseStyleId: payload.houseStyleId,
      };
    },
    saveSearchInput(state, { payload }) {
      return {
        ...state,
        input: payload.input,
      };
    },
    clearSearchInput(state) {
      return {
        ...state,
        input: undefined,
      };
    },

    saveLabels(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        labels: data,
      };
    },
  },
};

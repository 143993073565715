export var addLeadingSlash = function addLeadingSlash(path) {
  return path.charAt(0) === "/" ? path : "/" + path;
};

export var stripLeadingSlash = function stripLeadingSlash(path) {
  return path.charAt(0) === "/" ? path.substr(1) : path;
};

export var hasBasename = function hasBasename(path, prefix) {
  return new RegExp("^" + prefix + "(\\/|\\?|#|$)", "i").test(path);
};

export var stripBasename = function stripBasename(path, prefix) {
  return hasBasename(path, prefix) ? path.substr(prefix.length) : path;
};

export var stripTrailingSlash = function stripTrailingSlash(path) {
  return path.charAt(path.length - 1) === "/" ? path.slice(0, -1) : path;
};

export var parsePath = function parsePath(path) {
  var pathname = path || "/";
  var search = "";
  var hash = "";

  var hashIndex = pathname.indexOf("#");
  if (hashIndex !== -1) {
    hash = pathname.substr(hashIndex);
    pathname = pathname.substr(0, hashIndex);
  }

  var searchIndex = pathname.indexOf("?");
  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex);
    pathname = pathname.substr(0, searchIndex);
  }

  return {
    pathname: pathname,
    search: search === "?" ? "" : search,
    hash: hash === "#" ? "" : hash
  };
};

export var createPath = function createPath(location) {
  var pathname = location.pathname,
      search = location.search,
      hash = location.hash;


  var path = pathname || "/";

  if (search && search !== "?") path += search.charAt(0) === "?" ? search : "?" + search;

  if (hash && hash !== "#") path += hash.charAt(0) === "#" ? hash : "#" + hash;

  return path;
};
import { getWithPrefix, postWithPrefix, post } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/postsale/list', queryParam);
}

export async function listformpurchase(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/postsale/listfrompurchaseorder', queryParam);
}

export async function count(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/postsale/count', queryParam);
}

export async function findInfo(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/postsale/find', queryParam);
}

export async function bossDealApi(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/boss/postsale/addrecord', formData);
}

export async function add(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/postsale/add', formData);
}

export async function xshlist(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/postsale/listxsh', queryParam);
}

export async function siteDealApi(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/postsale/addrecord', formData);
}

export async function bossDealCancel(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return getWithPrefix('/postsale/canceldeal', updateParam);
}

import { get, post, getWithPrefix, postWithPrefix } from '@/utils/request';

export async function designlist(param = {}) {
  return getWithPrefix('/design/querystore', { queryParam: JSON.stringify(param) });
}

export async function designRoomlist(param = {}) {
  return getWithPrefix('/design/querydesignroomstore', { queryParam: JSON.stringify(param) });
}

export async function buildingdesignlist(param = {}) {
  return getWithPrefix('/design/querybuildingdesign', { queryParam: JSON.stringify(param) });
}

export async function buildingdesign(param = {}) {
  return getWithPrefix('/design/findbuildingdesign', { queryParam: JSON.stringify(param) });
}

export async function typelist(param = {}) {
  const queryParam = { queryParam: JSON.stringify(param) };
  return getWithPrefix('/housetype/suitlist', queryParam);
}

export async function roomlist(param = {}) {
  const queryParam = { queryParam: JSON.stringify(param) };
  return getWithPrefix('/housetype/roomlist', queryParam);
}

export async function stylelist(param = {}) {
  const queryParam = { queryParam: JSON.stringify(param) };
  return getWithPrefix('/housestyle/selectlist', queryParam);
}

export async function alltypelist(param = {}) {
  const queryParam = { queryParam: JSON.stringify(param) };
  return getWithPrefix('/housetype/selectlist', queryParam);
}

export async function designinfo(param) {
  const queryParam = { queryParam: JSON.stringify(param) };
  return getWithPrefix('/design/findstore', queryParam);
}

export async function findcustomer(param) {
  // 客户方案
  const queryParam = { queryParam: JSON.stringify(param) };
  return get('/site/design/findcustomer', queryParam);
}

export async function houseroomlistcustom(id) {
  const queryParam = { queryParam: JSON.stringify({ id }) };
  return getWithPrefix('/houseroom/selectlist', queryParam);
}

export async function housepartlistcustom(param = {}) {
  const queryParam = { queryParam: JSON.stringify(param) };
  return getWithPrefix('/housepart/selectlist', queryParam);
}

export async function designaddroompart(param) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('/design/addpart', updateParam);
}

// export async function productlist(param = {}) {
//   const queryParam = JSON.stringify(param);
//   const formData = new FormData();
//   formData.append('queryParam', queryParam);
//   return postWithPrefix('/goodsinfo/essearchproduct', formData);
// }

export async function queryProductListByGoodsIdSite(goodsId) {
  return getWithPrefix(`/queryProductListByGoodsId?goodsId=${goodsId}`);
}

export async function designaddroomproduct(param = {}) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('/design/addproduct', updateParam);
}

export async function designupdateroomproduct(param = {}) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('design/updateproduct', updateParam);
}

export async function designdeleteroompartproduct(param = {}) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('/design/removeproduct', updateParam);
}

export async function designdeleteroompart(param = {}) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('/design/removepart', updateParam);
}

export async function designdeleteroom(param = {}) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('/design/removeroom', updateParam);
}

export async function adddefineroom(param = {}) {
  const updateParam = { updateParam: JSON.stringify(param) };
  return getWithPrefix('/design/addroom', updateParam);
}

export async function designadd(param) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/design/addstore', formData);
}

export async function builddesignadd(param) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/design/addbuildingdesign', formData);
}

export async function addtotemplate(param) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/design/addtotemplate', formData);
}

export async function updatebuildingdesign(param) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/design/updatebuildingdesign', formData);
}

export async function designupdate(param) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/design/updatestore', formData);
}

export async function creatcustdesign(param) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/design/addcustomer', formData);
}

export async function updatecustomer(param = {}, options = {}) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/design/updatecustomer', formData, options);
}

export async function deletedesign(id) {
  const updateParam = { updateParam: JSON.stringify({ id }) };
  return getWithPrefix('/design/designdelete', updateParam);
}

export async function deletebuildingdesign(id) {
  const updateParam = { updateParam: JSON.stringify({ id }) };
  return getWithPrefix('/design/deletebuildingdesign', updateParam);
}

export async function deletecustomerdesign(id) {
  const updateParam = { updateParam: JSON.stringify({ id }) };
  return getWithPrefix('/design/deletecustomerdesign', updateParam);
}

export async function joinCart(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return postWithPrefix('/shoppingcart/addgoodsinfos', formData);
}

export async function queryallcustomerdesign(param = {}) {
  return get('/site/design/querycustomer', { queryParam: JSON.stringify(param) });
}

export async function newcustomerdesign(param = {}) {
  const updateParam = JSON.stringify(param);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/design/newcustomer', formData);
}

export async function lastdesign(param = {}) {
  // 上次方案
  return get('/site/design/lastcustomerdesign', { queryParam: JSON.stringify(param) });
}

export async function designProductNum(param = {}) {
  return get('/site/design/productscount', { queryParam: JSON.stringify(param) });
}

export async function exportCustomerDesign(params) {
  let target;
  if (APP_TYPE === 'site') {
    target = '//site.pei.nature-home.cn';
  } else if (APP_TYPE === 'boss') {
    target = '//boss.nature-home.cn';
  }
  const url = `${target}/${APP_TYPE}/design/customerdesigntoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

export async function exportTemplateDesign(params) {
  let target;
  if (APP_TYPE === 'site') {
    target = '//site.pei.nature-home.cn';
  } else if (APP_TYPE === 'boss') {
    target = '//boss.nature-home.cn';
  }
  const url = `${target}/${APP_TYPE}/design/templatedesigntoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

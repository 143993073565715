import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'D:/deergod/fDeerGod/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    name: 'user',
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'D:/deergod/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__NewLogin" */ '../User/NewLogin'),
              LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/NewLogin').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'D:/deergod/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/modpwd',
        name: 'modpwd',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'D:/deergod/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__ModPwd" */ '../User/ModPwd'),
              LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ModPwd').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        name: 'modpwd',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'D:/deergod/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/',
        redirect: '/homepage',
        exact: true,
      },
      {
        path: '/homepage',
        icon: 'icon-Home',
        name: 'homepage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Boss__HomePage__model.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/HomePage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Boss__HomePage__HomePage" */ '../Boss/HomePage/HomePage'),
              LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../Boss/HomePage/HomePage').default,
        exact: true,
      },
      {
        path: '/accounts',
        name: 'accounts',
        icon: 'icon-zhanghu1',
        routes: [
          {
            path: '/accounts',
            redirect: '/accounts/list',
            exact: true,
          },
          {
            path: '/accounts/group',
            name: 'group',
            icon: 'icon-fenzu1',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/accounts/group',
                redirect: '/accounts/group/list',
                exact: true,
              },
              {
                path: '/accounts/group/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Group__models__group.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Group/models/group.js').then(
                          m => {
                            return { namespace: 'group', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Group/GroupList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Group/GroupList').default,
                exact: true,
              },
            ],
          },
          {
            path: '/accounts/org',
            name: 'org',
            icon: 'icon-qiye1',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/accounts/org',
                redirect: '/accounts/org/list',
                exact: true,
              },
              {
                path: '/accounts/org/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Org__models__org.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Org/models/org.js').then(
                          m => {
                            return { namespace: 'org', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Org/OrgList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Org/OrgList').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                exact: true,
              },
              {
                path: '/accounts/org/view/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/OrgInfo'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/OrgInfo').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                routes: [
                  {
                    path: '/accounts/org/view/:id',
                    redirect: '/accounts/org/view/:id/department',
                    exact: true,
                  },
                  {
                    path: '/accounts/org/view/:id/department',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                              m => {
                                return { namespace: 'orgInfo', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                              m => {
                                return {
                                  namespace: 'widthdrawmodel',
                                  ...m.default,
                                };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/Department'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Site/Org/Department').default,
                    exact: true,
                  },
                  {
                    path: '/accounts/org/view/:id/invitedorg',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                              m => {
                                return { namespace: 'orgInfo', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                              m => {
                                return {
                                  namespace: 'widthdrawmodel',
                                  ...m.default,
                                };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/InvitedOrg'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Site/Org/InvitedOrg').default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/accounts/account',
            name: 'account',
            hideChildrenInMenu: true,
            icon: 'icon-account',
            routes: [
              {
                path: '/accounts/account',
                redirect: '/accounts/account/list',
                exact: true,
              },
              {
                path: '/accounts/account/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Account__models__account.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Account/models/account.js').then(
                          m => {
                            return { namespace: 'account', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Account/AccountList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Account/AccountList').default,
                exact: true,
              },
              {
                path: '/accounts/account/view/:id',
                name: 'view',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Account__models__account.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Account/models/account.js').then(
                          m => {
                            return { namespace: 'account', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Account/AccountView'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Account/AccountView').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: '/professional',
        name: 'professional',
        icon: 'icon-yewu',
        routes: [
          {
            path: '/professional',
            redirect: '/professional/main',
            exact: true,
          },
          {
            path: '/professional/main',
            name: 'mainprofessional',
            icon: 'icon-zhanghu5',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/professional/main',
                redirect: '/professional/main/list',
                exact: true,
              },
              {
                path: '/professional/main/list',
                name: 'mainlist',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Professional__models__accountModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Professional/models/accountModel.js').then(
                          m => {
                            return { namespace: 'accountModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Professional/ProfessionalMain'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Professional/ProfessionalMain').default,
                exact: true,
              },
            ],
          },
          {
            path: '/professional/child',
            name: 'childprofessional',
            icon: 'icon-zizhanghuguanli1',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/professional/child',
                redirect: '/professional/child/list',
                exact: true,
              },
              {
                path: '/professional/child/list',
                name: 'childlist',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Professional__models__accountModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Professional/models/accountModel.js').then(
                          m => {
                            return { namespace: 'accountModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Professional/ProfessionalChild'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Professional/ProfessionalChild').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: '/product',
        name: 'product',
        icon: 'icon-chanpin3',
        routes: [
          {
            path: '/product',
            redirect: '/product/produclist',
            exact: true,
          },
          {
            path: '/product/produclist',
            name: 'productlist',
            icon: 'icon-youenservices',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/product/produclist',
                redirect: '/product/produclist/list',
                exact: true,
              },
              {
                path: '/product/produclist/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Product__models__productModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Product/models/productModel.js').then(
                          m => {
                            return { namespace: 'productModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Product/ProductList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Product/ProductList').default,
                exact: true,
              },
              {
                path: '/product/produclist/add',
                name: 'add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Product__models__productModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Product/models/productModel.js').then(
                          m => {
                            return { namespace: 'productModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Product/ProductEdit'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Product/ProductEdit').default,
                exact: true,
              },
              {
                path: '/product/produclist/edit/:id',
                name: 'edit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Product__models__productModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Product/models/productModel.js').then(
                          m => {
                            return { namespace: 'productModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Product/ProductEdit'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Product/ProductEdit').default,
                exact: true,
              },
              {
                path: '/product/produclist/view/:id',
                name: 'view',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Product__models__productModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Product/models/productModel.js').then(
                          m => {
                            return { namespace: 'productModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Product/ProductView'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Product/ProductView').default,
                exact: true,
              },
            ],
          },
          {
            path: '/product/orderlist',
            name: 'orderlist',
            icon: 'icon-dingdan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/product/orderlist',
                redirect: '/product/orderlist/list',
                exact: true,
              },
              {
                path: '/product/orderlist/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Product__models__productModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Product/models/productModel.js').then(
                          m => {
                            return { namespace: 'productModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Product/OrderList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Product/OrderList').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'site',
        icon: 'icon-jichu',
        path: '/basic',
        routes: [
          {
            path: '/basic/org',
            name: 'org',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                      m => {
                        return { namespace: 'orgInfo', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                      m => {
                        return { namespace: 'widthdrawmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/OrgInfo'),
                  LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Site/Org/OrgInfo').default,
            hideChildrenInMenu: true,
            platFlag: true,
            icon: 'icon-qiyexinxi1',
            routes: [
              {
                path: '/basic/org',
                redirect: '/basic/org/department',
                exact: true,
              },
              {
                path: '/basic/org/department',
                name: 'info',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/Department'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/Department').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                exact: true,
              },
              {
                path: '/basic/org/invitedorg',
                name: 'invitedorg',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/InvitedOrg'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/InvitedOrg').default,
                btns: [
                  {
                    key: 'view',
                    label: '查看',
                  },
                ],
                exact: true,
              },
            ],
          },
          {
            path: '/basic/role',
            name: 'role',
            icon: 'icon-icon_jiaosexinxiguanli',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Site__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Role/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                      m => {
                        return { namespace: 'widthdrawmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Role/RoleList'),
                  LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Site/Role/RoleList').default,
            btns: [
              {
                key: 'edit',
                label: '修改',
              },
            ],
            exact: true,
          },
          {
            path: '/basic/settings',
            name: 'settings',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'D:/deergod/fDeerGod/src/pages/Account/Settings/models/geographic.js').then(
                      m => {
                        return { namespace: 'geographic', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/Info'),
                  LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/Settings/Info').default,
            icon: 'icon-gerenshezhi2',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/basic/settings',
                redirect: '/basic/settings/personal',
                exact: true,
              },
              {
                path: '/basic/settings/personal',
                name: 'personal',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'D:/deergod/fDeerGod/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/PersonalView'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/PersonalView').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                exact: true,
              },
              {
                path: '/basic/settings/invitedorg',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'D:/deergod/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/InvitedOrg'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/InvitedOrg').default,
                btns: [
                  {
                    key: 'view',
                    label: '查看',
                  },
                ],
                exact: true,
              },
              {
                path: '/basic/settings/security',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'D:/deergod/fDeerGod/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/SecurityView'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/SecurityView').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                exact: true,
              },
            ],
          },
          {
            path: '/basic/journal',
            name: 'journal',
            icon: 'icon-dingdan3',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Boss__Journal__models__journal.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Journal/models/journal.js').then(
                      m => {
                        return { namespace: 'journal', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Journal/JournalList'),
                  LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Boss/Journal/JournalList').default,
            exact: true,
          },
          {
            path: '/basic/abutment',
            name: 'abutment',
            icon: 'icon-logooo_huabanfuben',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Boss__AbutmentLog__models__abutmentModel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/AbutmentLog/models/abutmentModel.js').then(
                      m => {
                        return { namespace: 'abutmentModel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/AbutmentLog/AbutmentLogList'),
                  LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Boss/AbutmentLog/AbutmentLogList').default,
            exact: true,
          },
        ],
      },
      {
        name: 'boss',
        icon: 'icon-pingtaiguanli',
        path: '/setting',
        routes: [
          {
            path: '/setting/function',
            name: 'function',
            icon: 'icon-mobangongnengguanli',
            btns: [
              {
                key: 'edit',
                label: '修改',
              },
            ],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Boss__Function__models__functionmodel.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Function/models/functionmodel.js').then(
                      m => {
                        return { namespace: 'functionmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Function/FunctionList'),
                  LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Boss/Function/FunctionList').default,
            exact: true,
          },
          {
            path: '/setting/app',
            name: 'app',
            btns: [
              {
                key: 'edit',
                label: '修改',
              },
            ],
            hideChildrenInMenu: true,
            icon: 'icon-duliyuming-yingyongshichang',
            routes: [
              {
                path: '/setting/app',
                redirect: '/setting/app/list',
                exact: true,
              },
              {
                path: '/setting/app/list',
                name: 'list',
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                          m => {
                            return { namespace: 'app', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/App/AppList').default,
                exact: true,
              },
              {
                path: '/setting/app/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                          m => {
                            return { namespace: 'app', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppEdit'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/App/AppEdit').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/setting/app/edit/:id',
                    redirect: '/setting/app/edit/basic/:id',
                    exact: true,
                  },
                  {
                    path: '/setting/app/edit/basic/:id',
                    name: 'edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                              m => {
                                return { namespace: 'app', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppEditBasic'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/App/AppEditBasic').default,
                    exact: true,
                  },
                  {
                    path: '/setting/app/edit/advanced/:id',
                    name: 'advanced',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                              m => {
                                return { namespace: 'app', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppEditAdvanced'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/App/AppEditAdvanced').default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/setting/app/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                          m => {
                            return { namespace: 'app', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppEdit'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/App/AppEdit').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/setting/app/add',
                    redirect: '/setting/app/add/basic',
                    exact: true,
                  },
                  {
                    path: '/setting/app/add/basic',
                    name: 'edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                              m => {
                                return { namespace: 'app', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppEditBasic'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/App/AppEditBasic').default,
                    exact: true,
                  },
                  {
                    path: '/setting/app/add/advanced',
                    name: 'advanced',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                              m => {
                                return { namespace: 'app', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppEditAdvanced'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/App/AppEditAdvanced').default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/setting/app/view/:id',
                name: 'view',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__App__models__app.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/App/models/app.js').then(
                          m => {
                            return { namespace: 'app', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/App/AppView'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/App/AppView').default,
                exact: true,
              },
            ],
          },
          {
            path: '/setting/role',
            name: 'role',
            hideChildrenInMenu: true,
            icon: 'icon-icon_jiaosexinxiguanli',
            routes: [
              {
                path: '/setting/role',
                redirect: '/setting/role/list',
                exact: true,
              },
              {
                path: '/setting/role/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                          m => {
                            return { namespace: 'role', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Role/RoleList').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                exact: true,
              },
              {
                path: '/setting/role/edit/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                          m => {
                            return { namespace: 'role', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleEdit'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Role/RoleEdit').default,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/setting/role/edit/:id',
                    redirect: '/setting/role/edit/basic/:id',
                    exact: true,
                  },
                  {
                    path: '/setting/role/edit/basic/:id',
                    name: 'edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                              m => {
                                return { namespace: 'role', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleEditBasic'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/Role/RoleEditBasic').default,
                    exact: true,
                  },
                  {
                    path: '/setting/role/edit/advanced/:id',
                    name: 'advanced',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                              m => {
                                return { namespace: 'role', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleEditAdvanced'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/Role/RoleEditAdvanced').default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/setting/role/add',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                          m => {
                            return { namespace: 'role', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleEdit'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Role/RoleEdit').default,
                hideChildrenInMenu: true,
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                routes: [
                  {
                    path: '/setting/role/add',
                    redirect: '/setting/role/add/basic',
                    exact: true,
                  },
                  {
                    path: '/setting/role/add/basic',
                    name: 'edit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                              m => {
                                return { namespace: 'role', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleEditBasic'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/Role/RoleEditBasic').default,
                    exact: true,
                  },
                  {
                    path: '/setting/role/add/advanced',
                    name: 'advanced',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                              m => {
                                return { namespace: 'role', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleEditAdvanced'),
                          LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Boss/Role/RoleEditAdvanced').default,
                    exact: true,
                  },
                ],
              },
              {
                path: '/setting/role/view/:id',
                name: 'view',
                btns: [
                  {
                    key: 'view',
                    label: '查看',
                  },
                  {
                    key: 'edit',
                    label: '修改',
                  },
                ],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Role__models__role.js' */ 'D:/deergod/fDeerGod/src/pages/Boss/Role/models/role.js').then(
                          m => {
                            return { namespace: 'role', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Role/RoleView'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Role/RoleView').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'notification',
        icon: 'icon-xiaoxiguanli',
        path: '/notification',
        routes: [
          {
            path: '/notification/mynotice',
            name: 'mynotice',
            icon: 'icon-xiaoxi',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/notification/mynotice',
                redirect: '/notification/mynotice/list',
                exact: true,
              },
              {
                path: '/notification/mynotice/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Notification/NotificationList'),
                      LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Notification/NotificationList').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('D:/deergod/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}

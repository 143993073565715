export default [
  // user
  {
    path: '/user',
    component: '../layouts/UserLayout',
    name: 'user',
    routes: [
      { path: '/user', redirect: '/user/login' },
      { path: '/user/login', name: 'login', component: './User/NewLogin' },
      { path: '/user/register', name: 'register', component: './User/Register' },
      { path: '/user/modpwd', name: 'modpwd', component: './User/ModPwd' },
      { path: '/user/register-result', name: 'modpwd', component: './User/RegisterResult' },
    ],
  },
  // app
  {
    path: '/',
    component: '../layouts/BasicLayout',
    Routes: ['src/pages/Authorized'],
    // authority: ['admin', 'user'],
    routes: [
      // dashboard
      { path: '/', redirect: '/homepage' },
      {
        path: '/homepage',
        icon: 'icon-Home',
        name: 'homepage',
        component: './Boss/HomePage/HomePage',
      },
      {
        path: '/accounts',
        name: 'accounts',
        icon: 'icon-zhanghu1',
        routes: [
          {
            path: '/accounts',
            redirect: '/accounts/list',
          },
          {
            path: '/accounts/group',
            name: 'group',
            icon: 'icon-fenzu1',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/accounts/group',
                redirect: '/accounts/group/list',
              },
              {
                path: '/accounts/group/list',
                name: 'list',
                component: './Boss/Group/GroupList',
              },
            ],
          },
          {
            path: '/accounts/org',
            name: 'org',
            icon: 'icon-qiye1',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/accounts/org',
                redirect: '/accounts/org/list',
              },
              {
                path: '/accounts/org/list',
                name: 'list',
                component: './Boss/Org/OrgList',
                btns: [{ key: 'edit', label: '修改' }],
              },
              {
                path: '/accounts/org/view/:id',
                component: './Site/Org/OrgInfo',
                btns: [{ key: 'edit', label: '修改' }],
                routes: [
                  {
                    path: '/accounts/org/view/:id',
                    redirect: '/accounts/org/view/:id/department',
                  },
                  {
                    path: '/accounts/org/view/:id/department',
                    component: './Site/Org/Department',
                  },
                  {
                    path: '/accounts/org/view/:id/invitedorg',
                    component: './Site/Org/InvitedOrg',
                  },
                ],
              },
            ],
          },
          {
            path: '/accounts/account',
            name: 'account',
            hideChildrenInMenu: true,
            icon: 'icon-account',
            routes: [
              {
                path: '/accounts/account',
                redirect: '/accounts/account/list',
              },
              {
                path: '/accounts/account/list',
                name: 'list',
                component: './Boss/Account/AccountList',
              },
              {
                path: '/accounts/account/view/:id',
                name: 'view',
                component: './Boss/Account/AccountView',
              },
            ],
          },
        ],
      },
      {
        path: '/professional',
        name: 'professional',
        icon: 'icon-yewu',
        routes: [
          {
            path: '/professional',
            redirect: '/professional/main',
          },
          {
            path: '/professional/main',
            name: 'mainprofessional',
            icon: 'icon-zhanghu5',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/professional/main',
                redirect: '/professional/main/list',
              },
              {
                path: '/professional/main/list',
                name: 'mainlist',
                component: './Boss/Professional/ProfessionalMain',
              },
            ],
          },
          {
            path: '/professional/child',
            name: 'childprofessional',
            icon: 'icon-zizhanghuguanli1',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/professional/child',
                redirect: '/professional/child/list',
              },
              {
                path: '/professional/child/list',
                name: 'childlist',
                component: './Boss/Professional/ProfessionalChild',
              },
            ],
          },
        ],
      },
      {
        path: '/product',
        name: 'product',
        icon: 'icon-chanpin3',
        routes: [
          {
            path: '/product',
            redirect: '/product/produclist',
          },
          {
            path: '/product/produclist',
            name: 'productlist',
            icon: 'icon-youenservices',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/product/produclist',
                redirect: '/product/produclist/list',
              },
              {
                path: '/product/produclist/list',
                name: 'list',
                component: './Boss/Product/ProductList',
              },
              {
                path: '/product/produclist/add',
                name: 'add',
                component: './Boss/Product/ProductEdit',
              },
              {
                path: '/product/produclist/edit/:id',
                name: 'edit',
                component: './Boss/Product/ProductEdit',
              },
              {
                path: '/product/produclist/view/:id',
                name: 'view',
                component: './Boss/Product/ProductView',
              },
            ],
          },
          {
            path: '/product/orderlist',
            name: 'orderlist',
            icon: 'icon-dingdan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/product/orderlist',
                redirect: '/product/orderlist/list',
              },
              {
                path: '/product/orderlist/list',
                name: 'list',
                component: './Boss/Product/OrderList',
              },
            ],
          },
        ],
      },
      // {
      //   path: '/store',
      //   name: 'store',
      //   icon: 'icon-tuichu',
      //   routes: [
      //     {
      //       path: '/store',
      //       redirect: '/store/list',
      //     },
      //     {
      //       path: '/store/org',
      //       name: 'org',
      //       icon: 'icon-facebook',
      //       hideChildrenInMenu: true,
      //       routes: [
      //         {
      //           path: '/store/org',
      //           redirect: '/store/org/list',
      //         },
      //         {
      //           path: '/store/org/list',
      //           name: 'list',
      //           component: './Boss/Org/OrgList',
      //           btns: [{ key: 'edit', label: '修改' }],
      //         },
      //         {
      //           path: '/store/org/view/:id',
      //           component: './Site/Org/OrgInfo',
      //           btns: [{ key: 'edit', label: '修改' }],
      //           routes: [
      //             {
      //               path: '/store/org/view/:id',
      //               redirect: '/store/org/view/:id/department',
      //             },
      //             {
      //               path: '/store/org/view/:id/department',
      //               component: './Site/Org/Department',
      //             },
      //             {
      //               path: '/store/org/view/:id/invitedorg',
      //               component: './Site/Org/InvitedOrg',
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       path: '/store/list',
      //       name: 'list',
      //       icon: 'icon-facebook',
      //       component: './Site/Store/StoreList',
      //       btns: [{ key: 'edit', label: '修改' }],
      //     },

      //     {
      //       path: '/store/orgprotocol',
      //       name: 'orgprotocol',
      //       icon: 'icon-facebook',
      //       hideChildrenInMenu: true,
      //       routes: [
      //         {
      //           path: '/store/orgprotocol',
      //           redirect: '/store/orgprotocol/list',
      //         },
      //         {
      //           path: '/store/orgprotocol/list',
      //           name: 'list',
      //           component: './Boss/OrgProtocol/OrgProtocolList',
      //           btns: [{ key: 'edit', label: '修改' }],
      //         },
      //         {
      //           path: '/store/orgprotocol/add',
      //           name: 'add',
      //           component: './Boss/OrgProtocol/OrgProtocolEdit',
      //           btns: [{ key: 'edit', label: '修改' }],
      //         },
      //         {
      //           path: '/store/orgprotocol/edit/:id',
      //           name: 'edit',
      //           component: './Boss/OrgProtocol/OrgProtocolEdit',
      //           btns: [{ key: 'edit', label: '修改' }],
      //         },
      //         {
      //           path: '/store/orgprotocol/view/:id',
      //           name: 'view',
      //           component: './Boss/OrgProtocol/OrgProtocolView',
      //           btns: [{ key: 'edit', label: '修改' }],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: 'site',
        icon: 'icon-jichu',
        path: '/basic',
        routes: [
          {
            path: '/basic/org',
            name: 'org',
            component: './Site/Org/OrgInfo',
            hideChildrenInMenu: true,
            platFlag: true,
            icon: 'icon-qiyexinxi1',
            routes: [
              {
                path: '/basic/org',
                redirect: '/basic/org/department',
              },
              {
                path: '/basic/org/department',
                name: 'info',
                component: './Site/Org/Department',
                btns: [{ key: 'edit', label: '修改' }],
              },
              {
                path: '/basic/org/invitedorg',
                name: 'invitedorg',
                component: './Site/Org/InvitedOrg',
                btns: [{ key: 'view', label: '查看' }],
              },
            ],
          },
          {
            path: '/basic/role',
            name: 'role',
            icon: 'icon-icon_jiaosexinxiguanli',
            component: './Site/Role/RoleList',
            btns: [{ key: 'edit', label: '修改' }],
          },
          {
            path: '/basic/settings',
            name: 'settings',
            component: './Account/Settings/Info',
            icon: 'icon-gerenshezhi2',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/basic/settings',
                redirect: '/basic/settings/personal',
              },
              {
                path: '/basic/settings/personal',
                name: 'personal',

                component: './Account/Settings/PersonalView',
                btns: [{ key: 'edit', label: '修改' }],
              },
              {
                path: '/basic/settings/invitedorg',
                component: './Site/Org/InvitedOrg',
                btns: [{ key: 'view', label: '查看' }],
              },
              {
                path: '/basic/settings/security',
                component: './Account/Settings/SecurityView',
                btns: [{ key: 'edit', label: '修改' }],
              },
            ],
          },
          {
            path: '/basic/journal',
            name: 'journal',
            icon: 'icon-dingdan3',
            component: './Boss/Journal/JournalList',
          },
          {
            path: '/basic/abutment',
            name: 'abutment',
            icon: 'icon-logooo_huabanfuben',
            component: './Boss/AbutmentLog/AbutmentLogList',
          },
        ],
      },
      {
        name: 'boss',
        icon: 'icon-pingtaiguanli',
        path: '/setting',
        routes: [
          {
            path: '/setting/function',
            name: 'function',
            icon: 'icon-mobangongnengguanli',
            btns: [{ key: 'edit', label: '修改' }],
            component: './Boss/Function/FunctionList',
          },
          {
            path: '/setting/app',
            name: 'app',
            btns: [{ key: 'edit', label: '修改' }],
            hideChildrenInMenu: true,
            icon: 'icon-duliyuming-yingyongshichang',
            routes: [
              {
                path: '/setting/app',
                redirect: '/setting/app/list',
              },
              {
                path: '/setting/app/list',
                name: 'list',
                btns: [{ key: 'edit', label: '修改' }],
                component: './Boss/App/AppList',
              },
              {
                path: '/setting/app/edit/:id',
                component: './Boss/App/AppEdit',
                btns: [{ key: 'edit', label: '修改' }],
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/setting/app/edit/:id',
                    redirect: '/setting/app/edit/basic/:id',
                  },
                  {
                    path: '/setting/app/edit/basic/:id',
                    name: 'edit',
                    component: './Boss/App/AppEditBasic',
                  },
                  {
                    path: '/setting/app/edit/advanced/:id',
                    name: 'advanced',
                    component: './Boss/App/AppEditAdvanced',
                  },
                ],
              },
              {
                path: '/setting/app/add',
                component: './Boss/App/AppEdit',
                btns: [{ key: 'edit', label: '修改' }],
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/setting/app/add',
                    redirect: '/setting/app/add/basic',
                  },
                  {
                    path: '/setting/app/add/basic',
                    name: 'edit',
                    component: './Boss/App/AppEditBasic',
                  },
                  {
                    path: '/setting/app/add/advanced',
                    name: 'advanced',
                    component: './Boss/App/AppEditAdvanced',
                  },
                ],
              },
              {
                path: '/setting/app/view/:id',
                name: 'view',
                component: './Boss/App/AppView',
              },
            ],
          },
          {
            path: '/setting/role',
            name: 'role',
            hideChildrenInMenu: true,
            icon: 'icon-icon_jiaosexinxiguanli',
            routes: [
              {
                path: '/setting/role',
                redirect: '/setting/role/list',
              },
              {
                path: '/setting/role/list',
                name: 'list',
                component: './Boss/Role/RoleList',
                btns: [{ key: 'edit', label: '修改' }],
              },
              {
                path: '/setting/role/edit/:id',
                component: './Boss/Role/RoleEdit',
                btns: [{ key: 'edit', label: '修改' }],
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/setting/role/edit/:id',
                    redirect: '/setting/role/edit/basic/:id',
                  },
                  {
                    path: '/setting/role/edit/basic/:id',
                    name: 'edit',
                    component: './Boss/Role/RoleEditBasic',
                  },
                  {
                    path: '/setting/role/edit/advanced/:id',
                    name: 'advanced',
                    component: './Boss/Role/RoleEditAdvanced',
                  },
                ],
              },
              {
                path: '/setting/role/add',
                component: './Boss/Role/RoleEdit',
                hideChildrenInMenu: true,
                btns: [{ key: 'edit', label: '修改' }],
                routes: [
                  {
                    path: '/setting/role/add',
                    redirect: '/setting/role/add/basic',
                  },
                  {
                    path: '/setting/role/add/basic',
                    name: 'edit',
                    component: './Boss/Role/RoleEditBasic',
                  },
                  {
                    path: '/setting/role/add/advanced',
                    name: 'advanced',
                    component: './Boss/Role/RoleEditAdvanced',
                  },
                ],
              },
              {
                path: '/setting/role/view/:id',
                name: 'view',
                btns: [
                  { key: 'view', label: '查看' },
                  { key: 'edit', label: '修改' },
                ],
                component: './Boss/Role/RoleView',
              },
            ],
          },

          // 属性包
          // {
          //   path: '/setting/propertybag',
          //   name: 'propertybag',
          //   hideChildrenInMenu: true,
          //   routes: [
          //     {
          //       path: '/setting/propertybag',
          //       redirect: '/setting/propertybag/list',
          //     },
          //     {
          //       path: '/setting/propertybag/list',
          //       name: 'list',
          //       component: './Boss/PropertyBag/PropertyBagList',
          //       btns: [{ key: 'edit', label: '修改' }],
          //     },
          //     {
          //       path: '/setting/propertybag/add',
          //       name: 'add',
          //       component: './Boss/PropertyBag/PropertyBagEdit',
          //       btns: [{ key: 'edit', label: '修改' }],
          //     },
          //     {
          //       path: '/setting/propertybag/edit/:id',
          //       name: 'edit',
          //       component: './Boss/PropertyBag/PropertyBagEdit',
          //       btns: [{ key: 'edit', label: '修改' }],
          //     },
          //     {
          //       path: '/setting/propertybag/view/:id',
          //       name: 'view',
          //       component: './Boss/PropertyBag/PropertyBagView',
          //       btns: [{ key: 'edit', label: '修改' }],
          //     },
          //   ],
          // },
        ],
      },

      // 站点管理，帮助文档
      // {
      //   name: 'website',
      //   icon: 'bars',
      //   path: '/website',
      //   routes: [
      //     {
      //       path: '/website/helpdoc',
      //       name: 'helpdoc',
      //       hideChildrenInMenu: true,
      //       routes: [
      //         {
      //           path: '/website/helpdoc',
      //           redirect: '/website/helpdoc/list',
      //         },
      //         {
      //           path: '/website/helpdoc/list',
      //           name: 'list',
      //           component: './Boss/HelpDoc/HelpDocList',
      //         },
      //         {
      //           path: '/website/helpdoc/add',
      //           name: 'add',
      //           component: './Boss/HelpDoc/HelpDocEditor',
      //         },
      //         {
      //           path: '/website/helpdoc/edit/:id',
      //           name: 'edit',
      //           component: './Boss/HelpDoc/HelpDocEditor',
      //         },
      //         {
      //           path: '/website/helpdoc/view/:id',
      //           name: 'view',
      //           component: './Boss/HelpDoc/HelpDocView',
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        name: 'notification',
        icon: 'icon-xiaoxiguanli',
        path: '/notification',
        routes: [
          {
            path: '/notification/mynotice',
            name: 'mynotice',
            icon: 'icon-xiaoxi',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/notification/mynotice',
                redirect: '/notification/mynotice/list',
              },
              {
                path: '/notification/mynotice/list',
                name: 'list',
                component: './Notification/NotificationList',
              },
            ],
          },
        ],
      },

      // 资讯管理
      // {
      //   path: '/announcement',
      //   name: 'announcement',
      //   icon: 'gift',
      //   routes: [
      //     {
      //       path: '/announcement',
      //       name: 'announcement',
      //       hideChildrenInMenu: true,
      //       routes: [
      //         {
      //           path: '/announcement',
      //           redirect: '/announcement/list',
      //         },
      //         {
      //           path: '/announcement/list',
      //           name: 'list',
      //           component: './Boss/Announcement/AnnouncementList',
      //           btns: [{ key: 'edit', label: '修改' }],
      //         },
      //         {
      //           path: '/announcement/add',
      //           name: 'add',
      //           component: './Boss/Announcement/AnnouncementEdit',
      //         },
      //         {
      //           path: '/announcement/edit/:id',
      //           name: 'edit',
      //           component: './Boss/Announcement/AnnouncementEdit',
      //         },
      //       ],
      //     },
      //   ],
      // },
      // 应用管理
      // {
      //   path: '/appshop',
      //   name: 'appshop',
      //   icon: 'icon-yingyongshichang',
      //   component: './Site/AppShop/AppShopList',
      //   btns: [{ key: 'view', label: '查看' }],
      // },
      {
        component: '404',
      },
    ],
  },
];

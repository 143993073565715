import { post, get, getWithPrefix } from '@/utils/request';

export async function submit(params) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/order/ordersubmit', formData);
}

export async function find(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/order/orderfind', queryParam);
}

export async function fetchGoods(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/order/confirmorder', queryParam);
}

export async function querycustbind(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/customer/isbindkstore', queryParam);
}

export async function querygetqrcode(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/customer/getqrcode', queryParam);
}

export async function list(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/site/order/customerorderlist', queryParam);
}

export async function del(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/customer/getqrcode', queryParam);
}

export async function updateCust(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/order/updatecustomerinfo', formData);
}

export async function updateStatus(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/order/changestatus', formData);
}

export async function submitReceipt(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/receiptpayment/submitreceiptmentfromcustomerorder', formData);
}

export async function queryReceipt(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/receiptpayment/list', queryParam);
}

export async function findReceipt(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/receiptpayment/find', queryParam);
}

export async function cancleReceipt(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/receiptpayment/receiptecancel', updateParam);
}

export async function auditPassReceipt(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/receiptpayment/auditpass', updateParam);
}

export async function auditNoPassReceipt(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/receiptpayment/auditnotpass', updateParam);
}

export async function cancleAudit(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/receiptpayment/auditcancel', updateParam);
}

export async function exportExcel(params = {}) {
  const url = `http://site.pei.nature-home.cn/site/order/ordertoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

export async function orderGoods(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/order/getConfirmData', queryParam);
}

export async function findTransformInfo(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/site/order/confirmorder', updateParam);
}

export async function querywithdrawapplist(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return get('/boss/withdrawapplication/list', queryParam);
}

export async function updateWithdrawappStatus(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/withdrawapplication/updatestatus', updateParam);
}

export async function auditpass(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/withdrawapplication/auditpass', updateParam);
}

export async function auditnotpass(params = {}) {
  const updateParam = { updateParam: JSON.stringify(params) };
  return get('/boss/withdrawapplication/auditnotpass', updateParam);
}

export async function findhaspurchase(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return get('/site/purchaseorder/searchbycustomerorderid', queryParam);
}

export async function exportsettle(params = {}) {
  const url = `http://site.pei.nature-home.cn/site/order/ordertoexceladjust?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
}

export async function updateCustomerOrderDiscount(params = {}) {
  const updateParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('updateParam', updateParam);
  return post('/site/order/updatediscount', formData);
}

import { getWithPrefix } from '@/utils/request';

export async function list(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/composition/list', queryParam);
}

export async function queryone(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/composition/queryone', queryParam);
}

export async function find(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/composition/find', queryParam);
}

export async function regioncount({ brandId }) {
  const newParams = { brandId };
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/composition/compositionregioncount', queryParam);
}

export async function label({ brandId }) {
  const newParams = { brandId };
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/composition/labels', queryParam);
}

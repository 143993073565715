import { getWithPrefix, postWithPrefix } from '@/utils/request';

export async function list(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/announcement/list', queryParam);
}

export async function readlist(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/announcement/readlist', queryParam);
}

export async function add(params) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/announcement/add', formData);
}

export async function findInfo(params = {}) {
  const formData = new FormData();
  formData.append('queryParam', JSON.stringify(params));
  return postWithPrefix('/announcement/find', formData);
}

export async function update(params) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/announcement/update', formData);
}

export async function publish(params) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/announcement/publish', formData);
}

export async function unPublish(params) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/announcement/publishrevoke', formData);
}

export async function del(params) {
  const formData = new FormData();
  formData.append('updateParam', JSON.stringify(params));
  return postWithPrefix('/announcement/del', formData);
}

import { list, find } from '@/services/Designer/InformationAPI';
import { findProperty } from '@/services/propertyBagAPI';

export default {
  namespace: 'information',
  state: {
    information: {
      activeKey: '',
      listData: {},
      pagination: {},
      typeData: {},
    },
    xsh: {
      activeKey: '',
      listData: {},
      pagination: {},
      typeData: {},
    },
    wls: {
      activeKey: '',
      listData: {},
      pagination: {},
      typeData: {},
    },
    viewData: {},
  },

  effects: {
    *fetchList({ payload }, { call, put }) {
      const { key, pageNo, category } = payload;
      const response = yield call(list, {
        type: key === '全部' ? undefined : key,
        page: pageNo || 1,
        pageSize: 8,
        category,
      });
      if (!response) return;
      yield put({
        type: 'saveInformationList',
        payload: {
          data: response || [],
          key: key || '全部',
          pageNo: pageNo || 1,
        },
      });
    },

    *fetchXshList({ payload }, { call, put }) {
      const { key, pageNo, category } = payload;
      const response = yield call(list, {
        type: key === '全部' ? undefined : key,
        page: pageNo || 1,
        pageSize: 9,
        category,
      });
      if (!response) return;
      yield put({
        type: 'saveXshList',
        payload: {
          data: response || [],
          key: key || '全部',
          pageNo: pageNo || 1,
        },
      });
    },

    *fetchWlsList({ payload }, { call, put }) {
      const { key, pageNo, category } = payload;
      const response = yield call(list, {
        type: key === '全部' ? undefined : key,
        page: pageNo || 1,
        pageSize: 9,
        category,
      });
      if (!response) return;
      yield put({
        type: 'saveWlsList',
        payload: {
          data: response || [],
          key: key || '全部',
          pageNo: pageNo || 1,
        },
      });
    },

    *fetchView({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(find, { id });
      if (!response) return;
      yield put({
        type: 'saveData',
        payload: response,
      });
    },
    *fetchType({ payload, callback }, { call, put }) {
      const response = yield call(findProperty, { ...payload });
      if (!response) return;
      yield put({
        type: 'saveInformationType',
        payload: response,
      });
      if (callback) callback(response);
    },
    *fetchXshType({ payload, callback }, { call, put }) {
      const response = yield call(findProperty, { ...payload });
      if (!response) return;
      yield put({
        type: 'saveXshType',
        payload: response,
      });
      if (callback) callback(response);
    },
    *fetchWlsType({ payload, callback }, { call, put }) {
      const response = yield call(findProperty, { ...payload });
      if (!response) return;
      yield put({
        type: 'saveWlsType',
        payload: response,
      });
      if (callback) callback(response);
    },
  },

  reducers: {
    saveInformationList(state, action) {
      const { information } = state;
      const { payload: { key, pageNo = 1, data: { data: resultData } = {} } = {} } = action;
      const { pagination = {}, listData = {} } = information;
      pagination[key] = { page: pageNo };
      listData[key] = resultData;
      const newPagination = { ...pagination };
      const newList = { ...listData };
      return {
        ...state,
        information: {
          ...information,
          activeKey: key,
          pagination: newPagination,
          listData: newList,
        },
      };
    },

    saveXshList(state, action) {
      const { xsh } = state;
      const { payload: { key, pageNo = 1, data: { data: resultData } = {} } = {} } = action;
      const { pagination = {}, listData = {} } = xsh;
      pagination[key] = { page: pageNo };
      listData[key] = resultData;
      const newPagination = { ...pagination };
      const newList = { ...listData };
      return {
        ...state,
        xsh: {
          ...xsh,
          activeKey: key,
          pagination: newPagination,
          listData: newList,
        },
      };
    },

    saveWlsList(state, action) {
      const { wls } = state;
      const { payload: { key, pageNo = 1, data: { data: resultData } = {} } = {} } = action;
      const { pagination = {}, listData = {} } = wls;
      pagination[key] = { page: pageNo };
      listData[key] = resultData;
      const newPagination = { ...pagination };
      const newList = { ...listData };
      return {
        ...state,
        wls: {
          ...wls,
          activeKey: key,
          pagination: newPagination,
          listData: newList,
        },
      };
    },

    changeAcitiveKey(state, action) {
      const { information } = state;
      const { payload: { key } = {} } = action;
      return {
        ...state,
        information: {
          ...information,
          activeKey: key,
        },
      };
    },

    changeXshAcitiveKey(state, action) {
      const { xsh } = state;
      const { payload: { key } = {} } = action;
      return {
        ...state,
        xsh: {
          ...xsh,
          activeKey: key,
        },
      };
    },

    changeWlsAcitiveKey(state, action) {
      const { wls } = state;
      const { payload: { key } = {} } = action;
      return {
        ...state,
        wls: {
          ...wls,
          activeKey: key,
        },
      };
    },

    saveData(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        viewData: data,
      };
    },
    saveInformationType(state, action) {
      const { information } = state;
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        information: {
          ...information,
          typeData: data,
        },
      };
    },
    saveXshType(state, action) {
      const { xsh } = state;
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        xsh: {
          ...xsh,
          typeData: data,
        },
      };
    },
    saveWlsType(state, action) {
      const { wls } = state;
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        wls: {
          ...wls,
          typeData: data,
        },
      };
    },
  },
};

import { fetchHousestyle, joinCart } from '@/services/PackagedMealAPI';
import { list, find } from '@/services/Designer/DesignerDesignAPI';
import { fetchLabels } from '@/services/worksDisplayAPI';
import { brandlist } from '@/services/brandAPI';
import * as announcementAPI from '@/services/announcementAPI';
import * as WebSetAPI from '@/services/Designer/WebSetAPI';
import { findProperty } from '@/services/propertyBagAPI';
import router from 'umi/router';

const type = 'space';

export default {
  namespace: 'designerDesign',
  state: {
    list: {},
    view: {
      data: {}, // 方案详情
      idList: [], // [1,2,3,4,5]  上一个下一个方案的id列表
      index: 0, // 索引,上一个下一个寻找的依据
      historyLength: 0, // 历史路径长度
      getMore: () => {}, // 数组没有数据，找下一组
    },
    housestyles: [],
    region: undefined,
    input: undefined,
    houseStyleId: undefined,
    label: undefined,
    brands: [],
    hasMore: false,
    page: 1,
    announcement: {},
    banner: {},
    totalCount: 0,
    labels: [],
    brandId: undefined,
    priceTagType: {},
  },

  effects: {
    *fetchList({ payload, callback }, { call, put }) {
      const response = yield call(list, { ...payload, type });
      if (!response) return;
      if (callback) callback(response);
      const { page, pageSize } = payload;
      yield put({
        type: 'saveList',
        payload: response,
        page,
        pageSize,
      });
    },

    // *fetchView({ payload, callback }, { call, put }) {
    //   const response = yield call(queryone, { ...payload, type });
    //   if (!response) return;
    //   if (callback) callback(response);
    //   yield put({
    //     type: 'saveView',
    //     payload: response,
    //   });
    // },

    *find({ payload }, { call, put }) {
      const { id } = payload;
      const response = yield call(find, { id, type });
      if (!response) return;
      const { data } = response;
      yield put({
        type: 'saveView',
        payload: { data },
      });
    },

    *fetchBanner({ payload, callback }, { call, put }) {
      const response = yield call(WebSetAPI.info, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveBanner',
        payload: response,
      });
    },

    *fetchBrands({ payload, callback }, { call, put }) {
      const response = yield call(brandlist, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveBrands',
        payload: response,
      });
    },

    *fetchLabels({ payload }, { call, put }) {
      const response = yield call(fetchLabels, payload);
      if (!response) return;
      yield put({
        type: 'saveLabels',
        payload: response,
      });
    },

    *fetchAnnouncement({ payload, callback }, { call, put }) {
      const response = yield call(announcementAPI.readlist, payload);
      if (!response) return;
      if (callback) callback(response);
      yield put({
        type: 'saveAnnouncement',
        payload: response,
      });
    },

    *fetchHousestyle({ payload }, { call, put }) {
      const response = yield call(fetchHousestyle, payload);
      if (!response) return;
      yield put({
        type: 'saveHousestyle',
        payload: response,
      });
    },

    *joinCart({ payload, callback }, { call }) {
      const response = yield call(joinCart, payload);
      if (!response) return;
      if (callback) callback(response);
    },

    *changeView({ payload = {} }, { select, put }) {
      const { view } = yield select(state => state.designerDesign);
      const { action } = payload;
      const { historyLength, index, idList, getMore } = view;
      const newIndex = action === 'prev' ? index - 1 : index + 1;
      if (newIndex === idList.length - 1) {
        if (typeof getMore === 'function') {
          getMore();
        }
      }
      const id = idList[newIndex];
      yield put({
        type: 'find',
        payload: { id },
      });
      yield put({
        type: 'saveView',
        payload: { historyLength: historyLength + 1, index: newIndex },
      });
      router.push(`/composition/view/${id}`);
    },

    *peiChangeView({ payload = {} }, { select, put }) {
      const { view } = yield select(state => state.designerDesign);
      const { action } = payload;
      const { historyLength, index, idList, getMore } = view;
      const newIndex = action === 'prev' ? index - 1 : index + 1;
      if (newIndex === idList.length - 1) {
        if (typeof getMore === 'function') {
          getMore();
        }
      }
      const id = idList[newIndex];
      yield put({
        type: 'find',
        payload: { id },
      });
      yield put({
        type: 'saveView',
        payload: { historyLength: historyLength + 1, index: newIndex },
      });
      router.push(`/brand/design/view/${id}`);
    },
    *fetchProperty({ payload, callback }, { call, put }) {
      const response = yield call(findProperty, { ...payload });
      if (!response) return;
      yield put({
        type: 'saveType',
        payload: response,
      });
      if (callback) callback(response);
    },
  },

  reducers: {
    saveList(state, { payload = {}, page, pageSize }) {
      const { data = {} } = payload;
      let { modelList } = data;
      const { list: { modelList: oldArr = [] } = {} } = state;
      let hasMore = false;
      if (modelList.length === pageSize) hasMore = true;
      if (page !== 1) {
        modelList = oldArr.concat(modelList);
      }
      return {
        ...state,
        list: { ...data, modelList },
        hasMore,
        page,
      };
    },
    goToView(state, { payload = {} }) {
      const { view } = state;
      const { idList, index, getMore, brandId } = payload;
      return {
        ...state,
        brandId,
        view: { ...view, historyLength: 1, idList, index, getMore },
      };
    },
    saveView(state, { payload = {} }) {
      const { view } = state;
      return {
        ...state,
        view: { ...view, ...payload },
      };
    },
    saveLabels(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        labels: data,
      };
    },
    saveBrands(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        brands: data,
      };
    },
    saveAnnouncement(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        announcement: data,
      };
    },
    saveBanner(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        banner: data,
      };
    },
    saveHousestyle(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        housestyles: data,
      };
    },
    selectRadio(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveSearchInput(state, { payload }) {
      return {
        ...state,
        input: payload.input,
      };
    },
    clearSearchInput(state) {
      return {
        ...state,
        input: undefined,
      };
    },
    saveRegion(state, { payload }) {
      return {
        ...state,
        region: payload.region,
      };
    },
    clearData(state, { payload }) {
      const { param } = payload;
      const newState = { ...state };
      newState[param] = undefined;
      return {
        ...newState,
      };
    },

    saveType(state, action) {
      let data;
      if (action.payload) data = action.payload.data;
      return {
        ...state,
        priceTagType: data,
      };
    },
  },
};

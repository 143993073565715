export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'validation.email.required': '請輸入郵箱地址！',
  'validation.email.wrong-format': '郵箱地址格式錯誤！',
  'validation.password.required': '請輸入密碼！',
  'validation.password.twice': '兩次輸入的密碼不匹配!',
  'validation.password.strength.msg': '請至少輸入 6 個字符。請不要使用容易被猜到的密碼。',
  'validation.password.strength.strong': '強度：強',
  'validation.password.strength.medium': '強度：中',
  'validation.password.strength.short': '強度：太短',
  'validation.confirm-password.required': '請確認密碼！',
  'validation.phone-number.required': '請輸入手機號！',
  'validation.phone-number.wrong-format': '手機號格式錯誤！',
  'validation.verification-code.required': '請輸入驗證碼！',
  'validation.title.required': '請輸入標題',
  'validation.date.required': '請選擇起止日期',
  'validation.goal.required': '請輸入目標描述',
  'validation.standard.required': '請輸入衡量標淮',
  'form.captcha': '獲取驗證碼',
  'form.captcha.second': '秒',
  'form.optional': '（選填）',
  'form.submit': '提交',
  'form.save': '保存',
  'form.email.placeholder': '郵箱',
  'form.password.placeholder': '至少6位密碼，區分大小寫',
  'form.confirm-password.placeholder': '確認密碼',
  'form.phone-number.placeholder': '位手機號',
  'form.verification-code.placeholder': '驗證碼',
  'form.title.label': '標題',
  'form.title.placeholder': '給目標起個名字',
  'form.date.label': '起止日期',
  'form.date.placeholder.start': '開始日期',
  'form.date.placeholder.end': '結束日期',
  'form.goal.label': '目標描述',
  'form.goal.placeholder': '請輸入妳的階段性工作目標',
  'form.standard.label': '衡量標淮',
  'form.standard.placeholder': '請輸入衡量標淮',
  'form.client.label': '客戶',
  'form.client.label.tooltip': '目標的服務對象',
  'form.client.placeholder': '請描述妳服務的客戶，內部客戶直接 @姓名／工號',
  'form.invites.label': '邀評人',
  'form.invites.placeholder': '請直接 @姓名／工號，最多可邀請 5 人',
  'form.weight.label': '權重',
  'form.weight.placeholder': '請輸入',
  'form.public.label': '目標公開',
  'form.public.label.help': '客戶、邀評人默認被分享',
  'form.public.radio.public': '公開',
  'form.public.radio.partially-public': '部分公開',
  'form.public.radio.private': '不公開',
  'form.publicUsers.placeholder': '公開給',
  'form.publicUsers.option.A': '同事甲',
  'form.publicUsers.option.B': '同事乙',
  'form.publicUsers.option.C': '同事丙',
  'component.globalHeader.search': '站內搜索',
  'component.globalHeader.search.example1': '搜索提示壹',
  'component.globalHeader.search.example2': '搜索提示二',
  'component.globalHeader.search.example3': '搜索提示三',
  'component.globalHeader.help': '使用文檔',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': '妳已查看所有通知',
  'component.globalHeader.message': '消息',
  'component.globalHeader.message.empty': '您已讀完所有消息',
  'component.globalHeader.event': '待辦',
  'component.globalHeader.event.empty': '妳已完成所有待辦',
  'component.noticeIcon.clear': '清空',
  'component.noticeIcon.cleared': '清空了',
  'component.noticeIcon.empty': '暫無數據',
  'menu.home': '首頁',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析頁',
  'menu.dashboard.monitor': '監控頁',
  'menu.dashboard.workplace': '工作臺',
  'menu.form': '表單頁',
  'menu.form.basicform': '基礎表單',
  'menu.form.stepform': '分步表單',
  'menu.form.stepform.info': '分步表單（填寫轉賬信息）',
  'menu.form.stepform.confirm': '分步表單（確認轉賬信息）',
  'menu.form.stepform.result': '分步表單（完成）',
  'menu.form.advancedform': '高級表單',
  'menu.list': '列表頁',
  'menu.list.searchtable': '查詢表格',
  'menu.list.basiclist': '標淮列表',
  'menu.list.cardlist': '卡片列表',
  'menu.list.searchlist': '搜索列表',
  'menu.list.searchlist.articles': '搜索列表（文章）',
  'menu.list.searchlist.projects': '搜索列表（項目）',
  'menu.list.searchlist.applications': '搜索列表（應用）',
  'menu.profile': '詳情頁',
  'menu.profile.basic': '基礎詳情頁',
  'menu.profile.advanced': '高級詳情頁',
  'menu.result': '結果頁',
  'menu.result.success': '成功頁',
  'menu.result.fail': '失敗頁',
  'menu.exception': '異常頁',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '觸發錯誤',
  'menu.account': '個人頁',
  'menu.account.center': '個人中心',
  'menu.account.settings': '個人設置',
  'menu.account.trigger': '觸發報錯',
  'menu.account.logout': '退出登錄',
  'app.login.message-invalid-credentials': '賬戶或密碼錯誤（admin/ant.design）',
  'app.login.message-invalid-verification-code': '驗證碼錯誤',
  'app.login.tab-login-credentials': '賬戶密碼登錄',
  'app.login.tab-login-mobile': '手機號登錄',
  'app.login.remember-me': '自動登錄',
  'app.login.forgot-password': '忘記密碼',
  'app.login.sign-in-with': '其他登錄方式',
  'app.login.signup': '註冊賬戶',
  'app.login.login': '登錄',
  'app.register.register': '註冊',
  'app.register.get-verification-code': '獲取驗證碼',
  'app.register.sign-in': '使用已有賬戶登錄',
  'app.register-result.msg': '妳的賬戶：{email} 註冊成功',
  'app.register-result.activation-email':
    '激活郵件已發送到妳的郵箱中，郵件有效期為24小時。請及時登錄郵箱，點擊郵件中的鏈接激活帳戶。',
  'app.register-result.back-home': '返回首頁',
  'app.register-result.view-mailbox': '查看郵箱',
  'app.home.introduce': '介紹',
  'app.analysis.test': '工專路 {no} 號店',
  'app.analysis.introduce': '指標說明',
  'app.analysis.total-sales': '總銷售額',
  'app.analysis.day-sales': '日銷售額',
  'app.analysis.visits': '訪問量',
  'app.analysis.visits-trend': '訪問量趨勢',
  'app.analysis.visits-ranking': '門店訪問量排名',
  'app.analysis.day-visits': '日訪問量',
  'app.analysis.week': '周同比',
  'app.analysis.day': '日同比',
  'app.analysis.payments': '支付筆數',
  'app.analysis.conversion-rate': '轉化率',
  'app.analysis.operational-effect': '運營活動效果',
  'app.analysis.sales-trend': '銷售趨勢',
  'app.analysis.sales-ranking': '門店銷售額排名',
  'app.analysis.all-year': '全年',
  'app.analysis.all-month': '本月',
  'app.analysis.all-week': '本周',
  'app.analysis.all-day': '今日',
  'app.analysis.search-users': '搜索用戶數',
  'app.analysis.per-capita-search': '人均搜索次數',
  'app.analysis.online-top-search': '線上熱門搜索',
  'app.analysis.the-proportion-of-sales': '銷售額類別占比',
  'app.analysis.channel.all': '全部渠道',
  'app.analysis.channel.online': '線上',
  'app.analysis.channel.stores': '門店',
  'app.analysis.sales': '銷售額',
  'app.analysis.traffic': '客流量',
  'app.analysis.table.rank': '排名',
  'app.analysis.table.search-keyword': '搜索關鍵詞',
  'app.analysis.table.users': '用戶數',
  'app.analysis.table.weekly-range': '周漲幅',
  'app.forms.basic.title': '基礎表單',
  'app.forms.basic.description':
    '表單頁用於向用戶收集或驗證信息，基礎表單常見於數據項較少的表單場景。',
  'app.monitor.trading-activity': '活動實時交易情況',
  'app.monitor.total-transactions': '今日交易總額',
  'app.monitor.sales-target': '銷售目標完成率',
  'app.monitor.remaining-time': '活動剩余時間',
  'app.monitor.total-transactions-per-second': '每秒交易總額',
  'app.monitor.activity-forecast': '活動情況預測',
  'app.monitor.efficiency': '券核效率',
  'app.monitor.ratio': '跳出率',
  'app.monitor.proportion-per-category': '各品類占比',
  'app.monitor.fast-food': '中式快餐',
  'app.monitor.western-food': '西餐',
  'app.monitor.hot-pot': '火鍋',
  'app.monitor.waiting-for-implementation': 'Waiting for implementation',
  'app.monitor.popular-searches': '熱門搜索',
  'app.monitor.resource-surplus': '資源剩余',
  'app.monitor.fund-surplus': '補貼資金剩余',
  'app.settings.menuMap.basic': '基本設置',
  'app.settings.menuMap.security': '安全設置',
  'app.settings.menuMap.binding': '賬號綁定',
  'app.settings.menuMap.notification': '新消息通知',
  'app.settings.basic.avatar': '頭像',
  'app.settings.basic.change-avatar': '更換頭像',
  'app.settings.basic.email': '郵箱',
  'app.settings.basic.email-message': '請輸入您的郵箱!',
  'app.settings.basic.nickname': '昵稱',
  'app.settings.basic.nickname-message': '請輸入您的昵稱!',
  'app.settings.basic.profile': '個人簡介',
  'app.settings.basic.profile-message': '請輸入個人簡介!',
  'app.settings.basic.profile-placeholder': '個人簡介',
  'app.settings.basic.country': '國家/地區',
  'app.settings.basic.country-message': '請輸入您的國家或地區!',
  'app.settings.basic.geographic': '所在省市',
  'app.settings.basic.geographic-message': '請輸入您的所在省市!',
  'app.settings.basic.address': '街道地址',
  'app.settings.basic.address-message': '請輸入您的街道地址!',
  'app.settings.basic.phone': '聯系電話',
  'app.settings.basic.phone-message': '請輸入您的聯系電話!',
  'app.settings.basic.update': '更新基本信息',
  'app.settings.security.strong': '強',
  'app.settings.security.medium': '中',
  'app.settings.security.weak': '弱',
  'app.settings.security.password': '賬戶密碼',
  'app.settings.security.password-description': '當前密碼強度：',
  'app.settings.security.phone': '密保手機',
  'app.settings.security.phone-description': '已綁定手機：',
  'app.settings.security.question': '密保問題',
  'app.settings.security.question-description': '未設置密保問題，密保問題可有效保護賬戶安全',
  'app.settings.security.email': '備用郵箱',
  'app.settings.security.email-description': '已綁定郵箱：',
  'app.settings.security.mfa': 'MFA 設備',
  'app.settings.security.mfa-description': '未綁定 MFA 設備，綁定後，可以進行二次確認',
  'app.settings.security.modify': '修改',
  'app.settings.security.set': '設置',
  'app.settings.security.bind': '綁定',
  'app.settings.binding.taobao': '綁定淘寶',
  'app.settings.binding.taobao-description': '當前未綁定淘寶賬號',
  'app.settings.binding.alipay': '綁定支付寶',
  'app.settings.binding.alipay-description': '當前未綁定支付寶賬號',
  'app.settings.binding.dingding': '綁定釘釘',
  'app.settings.binding.dingding-description': '當前未綁定釘釘賬號',
  'app.settings.binding.bind': '綁定',
  'app.settings.notification.password': '賬戶密碼',
  'app.settings.notification.password-description': '其他用戶的消息將以站內信的形式通知',
  'app.settings.notification.messages': '系統消息',
  'app.settings.notification.messages-description': '系統消息將以站內信的形式通知',
  'app.settings.notification.todo': '賬戶密碼',
  'app.settings.notification.todo-description': '賬戶密碼',
  'app.settings.open': '開',
  'app.settings.close': '關',
  'app.exception.back': '返回首頁',
  'app.exception.description.403': '抱歉，妳無權訪問該頁面',
  'app.exception.description.404': '抱歉，妳訪問的頁面不存在',
  'app.exception.description.500': '抱歉，服務器出錯了',
  'app.result.error.title': '提交失敗',
  'app.result.error.description': '請核對並修改以下信息後，再重新提交。',
  'app.result.error.hint-title': '您提交的內容有如下錯誤：',
  'app.result.error.hint-text1': '您的賬戶已被凍結',
  'app.result.error.hint-btn1': '立即解凍',
  'app.result.error.hint-text2': '您的賬戶還不具備申請資格',
  'app.result.error.hint-btn2': '立即升級',
  'app.result.error.btn-text': '返回修改',
  'app.result.success.title': '提交成功',
  'app.result.success.description':
    '提交結果頁用於反饋壹系列操作任務的處理結果， 如果僅是簡單操作，使用 Message 全局提示反饋即可。 本文字區域可以展示簡單的補充說明，如果有類似展示 “單據”的需求，下面這個灰色區域可以呈現比較復雜的內容。',
  'app.result.success.operate-title': '項目名稱',
  'app.result.success.operate-id': '項目 ID：',
  'app.result.success.principal': '負責人：',
  'app.result.success.operate-time': '生效時間：',
  'app.result.success.step1-title': '創建項目',
  'app.result.success.step1-operator': '曲麗麗',
  'app.result.success.step2-title': '部門初審',
  'app.result.success.step2-operator': '周毛毛',
  'app.result.success.step2-extra': '催壹下',
  'app.result.success.step3-title': '財務復核',
  'app.result.success.step4-title': '完成',
  'app.result.success.btn-return': '返回列表',
  'app.result.success.btn-project': '查看項目',
  'app.result.success.btn-print': '打印',
  'app.setting.pagestyle': '整體風格設置',
  'app.setting.pagestyle.dark': '暗色菜單風格',
  'app.setting.pagestyle.light': '亮色菜單風格',
  'app.setting.content-width': '內容區域寬度',
  'app.setting.content-width.fixed': '定寬',
  'app.setting.content-width.fluid': '流式',
  'app.setting.themecolor': '主題色',
  'app.setting.themecolor.dust': '薄暮',
  'app.setting.themecolor.volcano': '火山',
  'app.setting.themecolor.sunset': '日暮',
  'app.setting.themecolor.cyan': '明青',
  'app.setting.themecolor.green': '極光綠',
  'app.setting.themecolor.daybreak': '拂曉藍（默認）',
  'app.setting.themecolor.geekblue': '極客藍',
  'app.setting.themecolor.purple': '醬紫',
  'app.setting.navigationmode': '導航模式',
  'app.setting.sidemenu': '側邊菜單布局',
  'app.setting.topmenu': '頂部菜單布局',
  'app.setting.fixedheader': '固定 Header',
  'app.setting.fixedsidebar': '固定側邊菜單',
  'app.setting.fixedsidebar.hint': '側邊菜單布局時可配置',
  'app.setting.hideheader': '下滑時隱藏 Header',
  'app.setting.hideheader.hint': '固定 Header 時可配置',
  'app.setting.othersettings': '其他設置',
  'app.setting.weakmode': '色弱模式',
  'app.setting.copy': '拷貝設置',
  'app.setting.copyinfo': '拷貝成功，請到 src/defaultSettings.js 中替換默認配置',
  'app.setting.production.hint':
    '配置欄只在開發環境用於預覽，生產環境不會展現，請拷貝後手動修改配置文件',
};

import { getWithPrefix, postWithPrefix } from '@/utils/request';

export async function list(params = {}) {
  const queryParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('queryParam', queryParam);
  return postWithPrefix('/goodsinfo/goodsinfolist', formData);
}

export async function selectGoods(params) {
  const queryParam = JSON.stringify(params);
  const formData = new FormData();
  formData.append('queryParam', queryParam);
  return postWithPrefix('/goodsinfo/goodsinfolist', formData);
}

export async function exportData(params) {
  // const tokenPlatForm = localStorage.getItem()
  let target;
  if (APP_TYPE === 'site') {
    target = 'http://site.pei.nature-home.cn';
  } else if (APP_TYPE === 'boss') {
    target = 'http://boss.nature-home.cn';
  }
  const url = `${target}/${APP_TYPE}/goodsinfo/goodsinfolisttoexcel?queryParam=${JSON.stringify(
    params
  )}`;
  window.open(encodeURI(url));
  // return getWithPrefix('/goodsinfo/goodsinfolisttoexcel', queryParam);
}

export async function fetchParams(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/goodsinfo/esqueryproductparam', queryParam);
}

export async function fetchBrands(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/goodsbrand/selectlist', queryParam);
}

export async function fetchCategory(params) {
  const newParams = params || {};
  const queryParam = { queryParam: JSON.stringify(newParams) };
  return getWithPrefix('/goodscategory/selectlist', queryParam);
}

export async function goodslist(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/purchaseorder/goodsinfolist', queryParam);
}

export async function adddownload(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/goodsinfo/downloadExcel', queryParam);
}

export async function quickAddGood(params = {}) {
  const queryParam = { queryParam: JSON.stringify(params) };
  return getWithPrefix('/goodsinfo/goodsinfolistquick', queryParam);
}
